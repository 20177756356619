import { useState } from 'react';
import { format } from 'date-fns';
import { nanoidGenerator } from 'features/common/helpers';

import {
	TypeServiceEnum,
	IInsurance,
	ICircuitService,
	ICarRental,
	ITrainService,
	IActivityService,
	IFerry,
	IThirdPartyPackage,
	IFlightAddOm,
	IOtherService,
	ITransferService,
	ICruiseService,
	IAccommodationService,
	IBusService,
	ServiceUnitEnum,
	IFlightService,
	IFlightServiceLeg,
	ISeatsDistribution,
	IServiceCostVariationLogs,
	ITicket,
} from 'features/salesOrder/types';

export function parseServiceData(
	service:
		| IInsurance
		| ICircuitService
		| ICarRental
		| ITrainService
		| IActivityService
		| IFerry
		| IThirdPartyPackage
		| IFlightAddOm
		| IOtherService
		| ITransferService
		| ICruiseService
		| IAccommodationService
		| IBusService
		| IFlightService,
	selectedServiceType: TypeServiceEnum,
	flightData: IFlightServiceLeg[] | null,
	seatsDistribution: ISeatsDistribution[] | null,
):
	| IInsurance
	| ICircuitService
	| ICarRental
	| ITrainService
	| IActivityService
	| IFerry
	| IThirdPartyPackage
	| IFlightAddOm
	| IOtherService
	| ITransferService
	| ICruiseService
	| IAccommodationService
	| IBusService
	| IFlightService {
	const parsedData:
		| IInsurance
		| ICircuitService
		| ICarRental
		| ITrainService
		| IActivityService
		| IFerry
		| IThirdPartyPackage
		| IFlightAddOm
		| IOtherService
		| ITransferService
		| ICruiseService
		| IAccommodationService
		| IBusService
		| IFlightService = {
		...service,
		serviceType: selectedServiceType,
	};

	const commonFields: (keyof (
		| IInsurance
		| ICircuitService
		| ICarRental
		| ITrainService
		| IActivityService
		| IFerry
		| IFlightAddOm
		| IOtherService
		| ITransferService
		| ICruiseService
		| IAccommodationService
		| IBusService
		| IFlightService
	))[] = ['saleAmount', 'cost', 'markupPercentage', 'totalCost', 'totalSaleAmount'];

	commonFields.forEach((field) => {
		const parsedValue = parseFloat((service as any)[field]);
		(parsedData as any)[field] = isNaN(parsedValue) ? 0 : parsedValue;
	});
	if (selectedServiceType === TypeServiceEnum.Insurance) {
		const insuranceService = parsedData as IInsurance;
		insuranceService.startDate = format(new Date(insuranceService.startDate), 'yyyy-MM-dd');
		insuranceService.endDate = format(new Date(insuranceService.endDate), 'yyyy-MM-dd');
		insuranceService.limitDate = format(new Date(insuranceService.limitDate), 'yyyy-MM-dd');
	}
	if (selectedServiceType === TypeServiceEnum.Circuit) {
		const circuitService = parsedData as ICircuitService;
		circuitService.startDate = format(new Date(circuitService.startDate), 'yyyy-MM-dd');
		circuitService.endDate = format(new Date(circuitService.endDate), 'yyyy-MM-dd');
		circuitService.limitDate = format(new Date(circuitService.limitDate), 'yyyy-MM-dd');
	}
	if (selectedServiceType === TypeServiceEnum.CarRental) {
		const carRentalService = parsedData as ICarRental;
		carRentalService.limitDate = format(new Date(carRentalService.limitDate), 'yyyy-MM-dd');
	}
	if (selectedServiceType === TypeServiceEnum.Train) {
		const trainService = parsedData as ITrainService;
		trainService.startDate = format(new Date(trainService.startDate), 'yyyy-MM-dd');
		trainService.endDate = format(new Date(trainService.endDate), 'yyyy-MM-dd');
		trainService.limitDate = format(new Date(trainService.limitDate), 'yyyy-MM-dd');

		if (seatsDistribution !== null) {
			trainService.seatsDistribution = seatsDistribution;
		}
	}
	if (selectedServiceType === TypeServiceEnum.Activity) {
		const activityService = parsedData as IActivityService;
		activityService.useDate = format(new Date(activityService.useDate), 'yyyy-MM-dd');
		activityService.limitDate = format(new Date(activityService.limitDate), 'yyyy-MM-dd');
	}
	if (selectedServiceType === TypeServiceEnum.Ferry) {
		const ferryService = parsedData as IFerry;
		ferryService.startDate = format(new Date(ferryService.startDate), 'yyyy-MM-dd');
		ferryService.endDate = format(new Date(ferryService.endDate), 'yyyy-MM-dd');
		ferryService.limitDate = format(new Date(ferryService.limitDate), 'yyyy-MM-dd');
	}
	if (selectedServiceType === TypeServiceEnum.ThirdPartyPackage) {
		const thirdPartyPackage = parsedData as IThirdPartyPackage;
		const numberFields: (keyof IThirdPartyPackage)[] = ['saleAmount', 'cost', 'markupPercentage'];

		numberFields.forEach((field) => {
			const parsedValue = parseFloat((service as any)[field]);
			(parsedData as any)[field] = isNaN(parsedValue) ? 0 : parsedValue;
		});

		thirdPartyPackage.startDate = format(new Date(thirdPartyPackage.startDate), 'yyyy-MM-dd');
		thirdPartyPackage.endDate = format(new Date(thirdPartyPackage.endDate), 'yyyy-MM-dd');
		thirdPartyPackage.limitDate = format(new Date(thirdPartyPackage.limitDate), 'yyyy-MM-dd');
	}
	if (selectedServiceType === TypeServiceEnum.Other) {
		const otherService = parsedData as IOtherService;
		otherService.startDate = format(new Date(otherService.startDate), 'yyyy-MM-dd');
		otherService.endDate = format(new Date(otherService.endDate), 'yyyy-MM-dd');
		otherService.limitDate = format(new Date(otherService.limitDate), 'yyyy-MM-dd');
	}
	if (selectedServiceType === TypeServiceEnum.Transfer) {
		const transferService = parsedData as ITransferService;
		if (transferService.transferSegments) {
			transferService.transferSegments = transferService.transferSegments.map((segment) => {
				return {
					...segment,
					startDate: format(new Date(segment.startDate), 'yyyy-MM-dd'),
					endDate: format(new Date(segment.endDate), 'yyyy-MM-dd'),
				};
			});
		}
	}
	if (selectedServiceType === TypeServiceEnum.Cruise) {
		const cruiseService = parsedData as ICruiseService;
		cruiseService.startDate = format(new Date(cruiseService.startDate), 'yyyy-MM-dd');
		cruiseService.endDate = format(new Date(cruiseService.endDate), 'yyyy-MM-dd');
		cruiseService.limitDate = format(new Date(cruiseService.limitDate), 'yyyy-MM-dd');
		if (cruiseService.rooms) {
			cruiseService.rooms.forEach((room) => {
				room.keyId = nanoidGenerator();
			});
		}
		if (typeof cruiseService.unit === 'string' && cruiseService.unit === ServiceUnitEnum.PerCabin) {
			delete cruiseService.cost;
			delete cruiseService.saleAmount;
		}
		if (typeof cruiseService.unit === 'string' && cruiseService.unit === ServiceUnitEnum.Person) {
			delete cruiseService.cost;
			delete cruiseService.saleAmount;
		}
		if (
			Array.isArray(cruiseService.unit) &&
			cruiseService.unit.includes(ServiceUnitEnum.Group) &&
			cruiseService.rooms
		) {
			cruiseService.rooms.forEach((room) => {
				delete room.cost;
				delete room.markupPercentage;
				delete room.saleAmount;
				delete room.totalCost;
				delete room.totalSaleAmount;
			});
		}

		cruiseService.rooms?.forEach((room) => {
			room.cost = cruiseService.unit.includes(ServiceUnitEnum.Group) ? undefined : room.cost;
			room.markupPercentage = cruiseService.unit.includes(ServiceUnitEnum.Group) ? undefined : room.markupPercentage;
			room.saleAmount = cruiseService.unit.includes(ServiceUnitEnum.Group) ? undefined : room.saleAmount;
			room.totalCost = cruiseService.unit.includes(ServiceUnitEnum.Group) ? undefined : room.totalCost;
			room.totalSaleAmount = cruiseService.unit.includes(ServiceUnitEnum.Group) ? undefined : room.totalSaleAmount;
		});
	}
	if (selectedServiceType === TypeServiceEnum.Accommodation) {
		const accommodationService = parsedData as IAccommodationService;

		if (accommodationService.startDate > accommodationService.endDate) {
			throw 'Alojamiento, fecha final no puede ser mayor a la inicial';
		}

		accommodationService.startDate = format(new Date(accommodationService.startDate), 'yyyy-MM-dd');
		accommodationService.endDate = format(new Date(accommodationService.endDate), 'yyyy-MM-dd');
		accommodationService.limitDate = format(new Date(accommodationService.limitDate), 'yyyy-MM-dd');

		if (accommodationService.tariffProvider === 'n/a') {
			delete accommodationService.tariffProvider;
			accommodationService.sameTariffServiceProvider = false;
		} else if (typeof accommodationService.tariffProvider === 'string') {
			// @ts-ignore
			accommodationService.tariffProviderId = accommodationService.tariffProvider;
			delete accommodationService.tariffProvider;
		}

		// @ts-ignore
		if (accommodationService.serviceProvider === 'n/a') {
			accommodationService.sameTariffServiceProvider = false;
			// @ts-ignore
			delete accommodationService.serviceProvider;
		}
		// @ts-ignore
		if (accommodationService.serviceProviderId === 'n/a') {
			accommodationService.sameTariffServiceProvider = false;
			// @ts-ignore
			delete accommodationService.serviceProviderId;
		}

		// @ts-ignore
		if (accommodationService.tariffProviderId === 'n/a') {
			// @ts-ignore
			delete accommodationService.tariffProviderId;
			accommodationService.sameTariffServiceProvider = false;
		}
		if (accommodationService.rooms) {
			accommodationService.rooms.forEach((room) => {
				room.keyId = nanoidGenerator();
			});
		}

		// @ts-ignore
		if (typeof accommodationService.unit === 'string' && accommodationService.unit === ServiceUnitEnum.PerRoom) {
			delete accommodationService.cost;
			delete accommodationService.saleAmount;
		} else {
			accommodationService.cost = accommodationService.totalCost;
			accommodationService.saleAmount = accommodationService.totalSaleAmount;
		}
		if (
			Array.isArray(accommodationService.unit) &&
			accommodationService.unit.includes(ServiceUnitEnum.Group) &&
			accommodationService.rooms
		) {
			accommodationService.rooms.forEach((room) => {
				delete room.cost;
				delete room.markupPercentage;
				delete room.saleAmount;
			});
		}

		// This validates that a passenger is does not appear in two different
		// rooms.

		const checkedPassegners: string[] = [];
		let dupes = 0;
		for (let roomIndex = 0; roomIndex < accommodationService.rooms.length; roomIndex++) {
			for (let paxIndex = 0; paxIndex < accommodationService.rooms[roomIndex].passengerIds.length; paxIndex++) {
				const thisPaxId = accommodationService.rooms[roomIndex].passengerIds[paxIndex];
				// if thisPaxId has not been checked
				if (!checkedPassegners.includes(thisPaxId)) {
					// check if paxId exists in some other room
					for (let roomCheckIndex = 0; roomCheckIndex < accommodationService.rooms.length; roomCheckIndex++) {
						const checkRoom = accommodationService.rooms[roomCheckIndex];
						if (checkRoom.passengerIds.includes(thisPaxId) && roomIndex !== roomCheckIndex) {
							dupes++;
						}
					}
				}
			}
		}
		if (dupes > 0) {
			throw 'Por cada sevicio de alohamiento, el pasajero solo puede peterencer a una habitacion';
		}

		accommodationService.rooms?.forEach((room) => {
			room.cost = accommodationService.unit.includes(ServiceUnitEnum.Group) ? undefined : room.cost;
			room.markupPercentage = accommodationService.unit.includes(ServiceUnitEnum.Group)
				? undefined
				: room.markupPercentage;
			room.saleAmount = accommodationService.unit.includes(ServiceUnitEnum.Group) ? undefined : room.saleAmount;
		});
	}
	if (selectedServiceType === TypeServiceEnum.Bus) {
		const busService = parsedData as IBusService;
		busService.startDate = format(new Date(busService.startDate), 'yyyy-MM-dd');
		busService.endDate = format(new Date(busService.endDate), 'yyyy-MM-dd');
		busService.limitDate = format(new Date(busService.limitDate), 'yyyy-MM-dd');

		if (seatsDistribution !== null) {
			busService.seatsDistribution = seatsDistribution;
		}
	}
	if (selectedServiceType === TypeServiceEnum.Flight) {
		const flightService = parsedData as IFlightService;
		if (flightService.gds === 'n/a') {
			delete flightService.gds;
		}
		if (flightService.oidIssueId === 'n/a') {
			delete flightService.oidIssueId;
		}

		if (flightService.tariffProvider === 'n/a') {
			delete flightService.tariffProvider;
		} else if (typeof flightService.tariffProvider === 'string') {
			// @ts-ignore
			flightService.tariffProviderId = flightService.tariffProvider;
			delete flightService.tariffProvider;
		}
		// @ts-ignore
		if (flightService.tariffProviderId === 'n/a') {
			// @ts-ignore
			delete flightService.tariffProviderId;
		}
		if (flightService.passengersTickets) {
			// @ts-ignore
			flightService.passengersTickets = Object.keys(flightService.passengersTickets)?.map((passengerId) => {
				return typeof passengerId === 'string'
					? ({
							passengerId,
							//@ts-ignore
							ticket: flightService.passengersTickets[passengerId],
					  } as ITicket)
					: (passengerId as ITicket);
			});
		}

		if (flightData) {
			flightService.flightLegs = flightData.map((flightLeg) => ({
				...flightLeg,
				keyId: nanoidGenerator(),
			}));
		}
	}

	return parsedData;
}
type TypeUnitType = { id: string; name: string };

export const excludeTypeUnits = (typeUnitArray: TypeUnitType[], typeUnitToExclude: string[]): TypeUnitType[] => {
	return typeUnitArray
		.filter((typeUnit) => !typeUnitToExclude.some((toExclude) => typeUnit.name === toExclude))
		.map((typeUnit) => ({ id: typeUnit.id.toString(), name: typeUnit.name }));
};

interface CostAndAmountProps {
	originalCost: number | null;
	saleAmount: number | null;
	markupPercentage: number | null;
	handleCostChange: (newValue: number) => void;
	handleSaleAmountChange: (newValue: number) => void;
	handleMarkupPercentageChange: (newValue: number) => void;
	setMarkupPercentage: React.Dispatch<React.SetStateAction<number | null>>;
}

export const useCostAndAmount = (): CostAndAmountProps => {
	const [originalCost, setOriginalCost] = useState<number | null>(null);
	const [saleAmount, setSaleAmount] = useState<number | null>(null);
	const [markupPercentage, setMarkupPercentage] = useState<number | null>(null);

	const handleCostChange = (newValue: number) => {
		setOriginalCost(newValue);
	};

	const handleSaleAmountChange = (newValue: number) => {
		if (originalCost !== null) {
			setSaleAmount(newValue);
			recalculatePercentage(originalCost, newValue);
		}
	};

	const handleMarkupPercentageChange = (newValue: number) => {
		setMarkupPercentage(newValue);

		if (originalCost !== null) {
			calculateSaleAmount(originalCost, newValue);
		}
	};

	const recalculatePercentage = (cost: number, amount: number) => {
		let newPercentage = 100 * (1 - cost / amount);
		if (amount < cost) {
			newPercentage = -Math.abs(newPercentage);
		}
		const roundedPercentage = isNaN(newPercentage) ? null : Number(newPercentage.toFixed(2));
		setMarkupPercentage(roundedPercentage);
	};

	const calculateSaleAmount = (cost: number, percentage: number | null) => {
		if (percentage !== null) {
			let saleAmountValue = cost / (1 - percentage / 100);
			saleAmountValue = Math.ceil(saleAmountValue * 100) / 100;
			setSaleAmount(isNaN(saleAmountValue) ? null : saleAmountValue);
		} else {
			setSaleAmount(null);
		}
	};

	return {
		markupPercentage,
		handleCostChange,
		handleSaleAmountChange,
		setMarkupPercentage,
		originalCost,
		saleAmount,
		handleMarkupPercentageChange,
	};
};

interface CostAndAmountPropsCruise {
	originalCostCruise: number | null;
	saleAmountCruise: (number | null)[];
	markupPercentageCruise: (number | null)[];
	handleCostChangeCruise: (newValue: number) => void;
	handleSaleAmountChangeCruise: (newValue: number, roomsIndex: number) => void;
	handleMarkupPercentageChangeCruise: (newValue: number, roomsIndex: number) => void;
	setMarkupPercentageCruise: React.Dispatch<React.SetStateAction<(number | null)[]>>;
}

export const useCostAndAmountCruise = (): CostAndAmountPropsCruise => {
	const [originalCostCruise, setOriginalCostCruise] = useState<number | null>(null);
	const [saleAmountCruise, setSaleAmountCruise] = useState<(number | null)[]>([]);
	const [markupPercentageCruise, setMarkupPercentageCruise] = useState<(number | null)[]>([]);

	const handleCostChangeCruise = (newValue: number) => {
		setOriginalCostCruise(newValue);
		setSaleAmountCruise([]);
	};

	const handleSaleAmountChangeCruise = (newValue: number, roomsIndex: number) => {
		if (originalCostCruise !== null) {
			newValue = parseFloat(newValue.toFixed(2));
			setSaleAmountCruise((prevSaleAmounts) => {
				const newSaleAmounts = [...prevSaleAmounts];
				newSaleAmounts[roomsIndex] = newValue;
				return newSaleAmounts;
			});
			recalculatePercentage(originalCostCruise, newValue, roomsIndex);
		}
	};

	const handleMarkupPercentageChangeCruise = (newValue: number, roomsIndex: number) => {
		newValue = parseFloat(newValue.toFixed(2));
		setMarkupPercentageCruise((prevMarkupPercentages) => {
			const newMarkupPercentages = [...prevMarkupPercentages];
			newMarkupPercentages[roomsIndex] = isNaN(newValue) ? null : newValue;

			if (originalCostCruise !== null) {
				calculateSaleAmountCruise(originalCostCruise, newValue, roomsIndex);
			}
			return newMarkupPercentages;
		});
	};

	const recalculatePercentage = (cost: number, amount: number, roomsIndex: number) => {
		let newPercentage = 100 * (1 - cost / amount);
		if (amount < cost) {
			newPercentage = -Math.abs(newPercentage);
		}
		newPercentage = Number(newPercentage.toFixed(2));
		setMarkupPercentageCruise((prevMarkupPercentages) => {
			const newMarkupPercentages = [...prevMarkupPercentages];
			newMarkupPercentages[roomsIndex] = isNaN(newPercentage) ? null : newPercentage;

			return newMarkupPercentages;
		});
	};

	const calculateSaleAmountCruise = (cost: number, percentage: number | null, roomsIndex: number) => {
		if (percentage !== null) {
			const saleAmountValue = cost / (1 - percentage / 100);
			const roundedSaleAmount = Number(saleAmountValue.toFixed(2));
			setSaleAmountCruise((prevSaleAmounts) => {
				const newSaleAmounts = [...prevSaleAmounts];
				newSaleAmounts[roomsIndex] = isNaN(roundedSaleAmount) ? null : roundedSaleAmount;
				return newSaleAmounts;
			});
		} else {
			setSaleAmountCruise((prevSaleAmounts) => {
				const newSaleAmounts = [...prevSaleAmounts];
				newSaleAmounts[roomsIndex] = null;
				return newSaleAmounts;
			});
		}
	};

	return {
		markupPercentageCruise,
		handleCostChangeCruise,
		handleSaleAmountChangeCruise,
		setMarkupPercentageCruise,
		originalCostCruise,
		saleAmountCruise,
		handleMarkupPercentageChangeCruise,
	};
};

export const filterServiceCostVariationLogs = (
	array: IServiceCostVariationLogs[],
	idService: string,
): IServiceCostVariationLogs[] => {
	return array.filter((item) => item.serviceId === idService);
};

export const calcularPrecioFinal = (cost: number, markup: number): string => {
	const markupDecimal = markup * 0.01;
	return Number(cost / (1 - markupDecimal)).toFixed(2);
};

export const calculatMarkupPercentaje = (cost: number, finalPrice: number): string => {
	if (finalPrice === 0) {
		return '-100.00';
	}
	const markupDecimal = 1 - cost / finalPrice;
	return Number(markupDecimal * 100).toFixed(2);
};

export const returnPaxName = (
	passengers: {
		keyId: string;
		name: string;
		lastName: string;
	}[],
	paxId: string,
): string => {
	const result = passengers.find((pax) => pax.keyId === paxId);
	if (result) {
		return `${result.name} ${result.lastName}`;
	}
	return 'not found';
};
