import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FieldErrors, FormProvider, Resolver, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { Box, Button, Divider, Grid, TextField, Typography, FormControlLabel, Switch, Checkbox } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStyles } from 'features/salesOrder/components/SalesOrderPassagerForm/styles';
import { checkShowErrorMessage, nanoidGenerator } from 'features/common/helpers';
import {
	ICodeName,
	IDocumentForm,
	IDocuments,
	IFrequentFlyerNumber,
	IPassengerForm,
	NetsuitePlaces,
	NetsuiteTaxDataResponse,
	PassengerDocumentEnum,
	passengerDocumentType,
	typeNationalites,
} from 'features/salesOrder/types';
// import { DragDrop } from 'features/salesOrder/components/SalesOrderPassagerForm/dragDrop';
import { countries } from 'features/common/constants';
import { PassagerModal } from 'features/salesOrder/components/SaleOrderPassagerModal';
import { getPax, updatePax, netsuiteTaxData } from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import { ControlledSelect, Option } from 'features/common/components/ControlledSelect';
import { ControlledDatePicker } from 'features/common/components/ControlledDatePicker';
import { ControlledCheckBox } from 'features/common/components/ControlledCheckBox';
import { INetsuiteSyncStatusDTO, ShowAlertState } from 'features/common/types';

import { paxFormFields } from './constants';
import {
	doesDocumentNeedVerification,
	getFieldType,
	getMunicipalities,
	getPaxField,
	getProvinces,
	getFieldRuleOption,
	getState,
	getTaxData,
	payloadParser,
	getNsCountryId,
	formatCedula,
} from './helpers';
import { fieldRuleOptions, renderOptions } from './types';
import NetsuiteEntitySyncStatus from '../../../Netsuite/components/NetsuiteEntitySyncStatus';
import ImageUploader from './ImageUploader';
import { fetchAirlineCodes } from 'services';

interface Props {
	passenger: IPassengerForm;
	onUpdatePassengers: () => void;
	onRemove: (keyId: string) => void;
	isInDb?: boolean;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	places: NetsuitePlaces;
}

const createPassengerFormSchema = (authCountry: string, isFacturable: boolean, places: NetsuitePlaces) => {
	const mercadoId = getNsCountryId(places, authCountry);
	return yup.object().shape({
		keyId: yup.string().required('Key ID es requerido'),
		name: yup.string().required('Nombre es requerido'),
		lastName: yup.string().required('Apellido es requerido'),
		state: yup.string().when(['countryId'], (fields: any, schema: any) => {
			return isFacturable && ['CO', 'BO', 'PY', 'UY'].includes(authCountry) && mercadoId === fields[0]
				? schema.required('Estado es requerido')
				: schema.nullable();
		}),
		province: yup.string().when(['state', 'countryId'], (state: any, schema: any) => {
			return (isFacturable && ['CO', 'BO', 'UY', 'PY'].includes(authCountry) && mercadoId === state[1]) ||
				(state[0] !== String(idAsuncion) && authCountry === 'PY' && mercadoId === state[1])
				? schema.required('Ciudad es requerida')
				: schema.nullable();
		}),
		municipality: yup.string().when(['countryId'], (fields: any, schema: any) => {
			return isFacturable && ['BO', 'PY'].includes(authCountry) && mercadoId === fields[0]
				? schema.required('Municipio/Comuna es requerido')
				: schema.nullable();
		}),
		countryId: yup.string().nullable(),
		birthdate: yup.string().nullable(),
		mainPassenger: yup.boolean().nullable(),
		documents: yup
			.array()
			.of(
				yup.object().shape({
					keyId: yup.string().required('Key ID es requerido'),
					documentType: yup.string().required('Tipo de documento es requerido'),
					country: yup.string().nullable(),
					documentNumber: yup.string().nullable(),
					expirationDate: yup.string().nullable(),
					image: yup.string().nullable(),
				}),
			)
			.required('Documentos son requeridos'),
		frequentFlyerNumbers: yup.array().of(
			yup.object().shape({
				airlineCode: yup.string().required('Key ID es requerido'),
				frequentFlyerNumber: yup.string().required('Tipo de documento es requerido'),
			}),
		),
		ageAtEndTravel: yup.number().nullable().positive('La edad al final del viaje debe ser un número positivo'),
		phone: yup.string().max(35, 'Key ID debe tener como máximo 35 caracteres').default(''),
		email: yup.string().nullable().email('Correo electrónico no es válido'),
		taxPayerTypeId: yup.string().when([], (_fields: any, schema: any) => {
			return isFacturable && ['CO'].includes(authCountry)
				? schema.required('Tax Payer Id Requerido')
				: schema.nullable();
		}),
		taxRegimeId: yup.string().when([], (_fields: any, schema: any) => {
			return isFacturable && ['CO'].includes(authCountry)
				? schema.required('taxRegimeId Requerido')
				: schema.nullable();
		}),
		taxResidenceTypeId: yup.string().when([], (_fields: any, schema: any) => {
			return isFacturable && ['CO'].includes(authCountry)
				? schema.required('taxResidenceTypeId Requerido')
				: schema.nullable();
		}),
		fiscalResponsibilityId: yup.string().when([], (_fields: any, schema: any) => {
			return isFacturable && ['CO'].includes(authCountry)
				? schema.required('taxResidenceTypeId Requerido')
				: schema.nullable();
		}),
	});
};

export const customYupResolver: Resolver<IPassengerForm> = async (values, context) => {
	// @ts-ignore
	const { authCountry, invoiceable, places } = context || {};
	const schema = createPassengerFormSchema(authCountry, invoiceable, places);

	try {
		const validatedData = await schema.validate(values, { abortEarly: false });

		return {
			values: validatedData,
			errors: {} as FieldErrors<IPassengerForm>,
		};
	} catch (err) {
		// @ts-ignore
		const errors = err.inner.reduce((acc: FieldErrors<IPassengerForm>, error: yup.ValidationError) => {
			// @ts-ignore
			acc[error.path] = {
				type: error.type || 'validation',
				message: error.message,
			};
			return acc;
		}, {});

		return {
			values: {},
			errors,
		};
	}
};

const idAsuncion = 46;
export const PassengerForm = ({
	onUpdatePassengers,
	onRemove,
	passenger,
	onSetAlert,
	onSetLoading,
	places,
}: Props): JSX.Element => {
	const [documents, setDocuments] = useState<IDocumentForm[]>([]);
	const [ffNumbers, setFfNumbers] = useState<IFrequentFlyerNumber[]>([]);
	const [airlines, setAirlines] = useState<ICodeName[]>([]);
	const classes = useStyles();
	const { auth } = useSelector((state: RootState) => state);
	const { id: saleOrderId } = useParams<{ id: string }>();
	const [openModal, setOpenModal] = useState(false);
	const [taxData, setTaxData] = useState<null | NetsuiteTaxDataResponse>(null);
	const [invoiceable, setInvoiceable] = useState(false);
	const [isPhysicalPerson, setIsPhysicalPerson] = useState(true);
	const [netsuiteSyncStatus, setNetsuiteSycStatus] = useState<null | INetsuiteSyncStatusDTO>(passenger?.syncStatus);
	const toggleInvoiceable = () => setInvoiceable((prevState) => !prevState);
	const toggleIsPhysicalPerson = () => setIsPhysicalPerson((prevState) => !prevState);
	const toggleModal = () => setOpenModal((prev) => !prev);
	const theCountry = auth.country === 'TN' ? 'PY' : auth.country;

	const methods = useForm<IPassengerForm>({
		mode: 'all',
		resolver: customYupResolver,
		context: { authCountry: theCountry, invoiceable, places },
	});
	const {
		control,
		setValue,
		register,
		watch,
		formState: { errors },
	} = methods;

	const watchState = watch('state');
	const watchCountry = watch('countryId');
	const watchProvince = watch('province');
	const watchDocumentType = watch('documentType');

	const { remove } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormProvider)
		name: 'documents', // unique name for your Field Array
	});

	const handleOptionSelection = async (paxId: string) => {
		try {
			const { data } = await getPax({ paxId });
			const parsedPax: IPassengerForm = {
				...data,
				countryId: data.countryId,
				documents: data.documents.map((doc: IDocuments) => ({
					...doc,
					country: doc.country.code,
				})),
			};
			parsedPax.documents && setDocuments(parsedPax.documents);

			if (data.frequentFlyerNumbers) {
				setFfNumbers(data.frequentFlyerNumbers);
			}
			setIsPhysicalPerson(data.isPhysicalPerson);
			setNetsuiteSycStatus(data.syncStatus);
			setValue('keyId', parsedPax.keyId, { shouldDirty: true });
			setValue('name', parsedPax.name, { shouldDirty: true });
			setValue('lastName', parsedPax.lastName, { shouldDirty: true });
			setValue('countryId', parsedPax.countryId, { shouldDirty: true });
			setValue('birthdate', parsedPax.birthdate, { shouldDirty: true });
			setValue('ageAtEndTravel', parsedPax.ageAtEndTravel, { shouldDirty: true });
			setValue('phone', parsedPax.phone, { shouldDirty: true });
			setValue('email', parsedPax.email, { shouldDirty: true });
			setValue('mainPassenger', parsedPax.mainPassenger, { shouldDirty: true });
			setValue('documents', parsedPax.documents, { shouldDirty: true });

			setValue('taxPayerTypeId', parsedPax.taxPayerTypeId, { shouldDirty: true });
			setValue('taxRegimeId', parsedPax.taxRegimeId, { shouldDirty: true });
			setValue('taxResidenceTypeId', parsedPax.taxResidenceTypeId, { shouldDirty: true });
			setValue('fiscalResponsibilityId', parsedPax.fiscalResponsibilityId, { shouldDirty: true });
			setValue('postalCode', parsedPax.postalCode, { shouldDirty: true });
			setValue('state', parsedPax.state, { shouldDirty: true });
			setValue('province', parsedPax.province, { shouldDirty: true });
			setValue('municipality', parsedPax.municipality, { shouldDirty: true });
			setValue('address', parsedPax.address, { shouldDirty: true });
			setValue('documentType', parsedPax.documentType, { shouldDirty: true });
			if (
				theCountry === 'UY' &&
				parsedPax.documentType === 1 &&
				parsedPax.documentNumber &&
				parsedPax.documentVerificationNumber
			) {
				setValue('documentNumber', formatCedula(parsedPax.documentNumber, parsedPax.documentVerificationNumber), {
					shouldDirty: true,
				});
			} else {
				setValue('documentNumber', parsedPax.documentNumber, { shouldDirty: true });
			}
			setValue('documentVerificationNumber', parsedPax.documentVerificationNumber, { shouldDirty: true });
		} catch (e) {
			console.log(e);
			onSetAlert({
				show: true,
				severity: 'error',
				message: 'Error al traer pasajero',
			});
		}
	};

	const handleRemovePax = useCallback(() => {
		onRemove(passenger.keyId);
	}, [passenger]);

	const handleAddDocument = () => {
		const newDoc = {
			keyId: nanoidGenerator(),
			documentType: PassengerDocumentEnum.CEDULA,
			country: theCountry,
			documentNumber: '',
			expirationDate: '',
		};

		setDocuments((prevState) => {
			const newState = [...prevState, newDoc];
			setValue('documents', newState, { shouldDirty: true });
			return newState;
		});
	};

	const handleDeleteDocument = (docIndex: number) => () => {
		setDocuments((prevState) => {
			const newState = [...prevState];
			newState.splice(docIndex, 1);
			remove(docIndex);
			return newState;
		});
	};

	const handleImageUpload = (docIndex: number) => (imageBase64: string) => {
		setDocuments((prevState) => {
			const newDocs = [...prevState];
			newDocs[docIndex].image = imageBase64;
			return newDocs;
		});
	};

	const handleAddFF = () => {
		const newFF = {
			airlineCode: '',
			frequentFlyerNumber: '',
		};

		setFfNumbers((prevState) => {
			const newState = [...prevState, newFF];
			setValue('frequentFlyerNumbers', newState, { shouldDirty: true });
			return newState;
		});
	};

	const handleDeleteFF = (docIndex: number) => () => {
		setFfNumbers((prevState) => {
			const newState = [...prevState];
			newState.splice(docIndex, 1);
			remove(docIndex);
			setValue('frequentFlyerNumbers', newState, { shouldDirty: true });
			return newState;
		});
	};

	const handleSavePassenger = async (data: IPassengerForm) => {
		try {
			if (taxData) {
				onSetLoading(true);

				const payloadParsed = payloadParser(
					theCountry,
					invoiceable,
					isPhysicalPerson,
					data,
					String(getNsCountryId(places, theCountry)),
					taxData,
				);

				if (payloadParsed === false) {
					return;
				}

				const payload = {
					...payloadParsed,
					id: data.id,
					seatPreference: data.seatPreference,
					keyId: data.keyId,
					isPhysicalPerson: Boolean(isPhysicalPerson),
					invoiceable: !isPhysicalPerson ? true : Boolean(invoiceable),
					mainPassenger: !isPhysicalPerson ? false : Boolean(data.mainPassenger),
					documents: data.documents.map((doc, index) => {
						const b = countries.find((country) => country.code === doc.country);
						const expirationDate = doc.expirationDate;

						const toReturn = {
							...doc,
							keyId: doc.keyId,
							documentType: doc.documentType,
							expirationDate,
							country: {
								code: doc.country,
								name: `${b?.name}`,
							},
						};
						if (documents[index].image) {
							toReturn.image = documents[index].image;
						}
						return toReturn;
					}),
				};

				await updatePax({
					saleOrderId,
					paxId: data.keyId,
					// @ts-ignore
					payload,
				});
				onSetAlert({
					show: true,
					severity: 'success',
					message: 'Pasajero guardado!',
				});
				onUpdatePassengers();
			}
		} catch (e) {
			onSetAlert({
				show: true,
				severity: 'error',
				// @ts-ignore
				message: e.response.data.message,
			});
		} finally {
			onSetLoading(false);
		}
	};

	useEffect(() => {
		passenger.documents && setDocuments(passenger.documents);
		if (passenger.frequentFlyerNumbers) {
			setFfNumbers(passenger.frequentFlyerNumbers);
			setValue('frequentFlyerNumbers', passenger.frequentFlyerNumbers, { shouldDirty: true });
		}
		setIsPhysicalPerson(passenger.isPhysicalPerson);
		setInvoiceable(passenger.invoiceable);
		setValue('id', passenger.id, { shouldDirty: true });
		setValue('keyId', passenger.keyId, { shouldDirty: true });
		setValue('name', passenger.name, { shouldDirty: true });
		setValue('lastName', passenger.lastName, { shouldDirty: true });
		setValue('countryId', passenger.countryId, { shouldDirty: true });
		setValue('birthdate', passenger.birthdate, { shouldDirty: true });
		setValue('ageAtEndTravel', passenger.ageAtEndTravel, { shouldDirty: true });
		setValue('phone', passenger.phone, { shouldDirty: true });
		setValue('email', passenger.email, { shouldDirty: true });
		setValue('mainPassenger', passenger.mainPassenger, { shouldDirty: true });
		setValue('documents', passenger.documents, { shouldDirty: true });
		setValue('taxPayerTypeId', passenger.taxPayerTypeId, { shouldDirty: true });
		setValue('taxRegimeId', passenger.taxRegimeId, { shouldDirty: true });
		setValue('taxResidenceTypeId', passenger.taxResidenceTypeId, { shouldDirty: true });
		setValue('fiscalResponsibilityId', passenger.fiscalResponsibilityId, { shouldDirty: true });
		setValue('postalCode', passenger.postalCode, { shouldDirty: true });
		setValue('state', passenger.state, { shouldDirty: true });
		setValue('province', passenger.province, { shouldDirty: true });
		setValue('municipality', passenger.municipality, { shouldDirty: true });
		setValue('address', passenger.address, { shouldDirty: true });
		setValue('documentType', passenger.documentType, { shouldDirty: true });
		if (
			theCountry === 'UY' &&
			passenger.documentType === 1 &&
			passenger.documentNumber &&
			passenger.documentVerificationNumber
		) {
			setValue('documentNumber', formatCedula(passenger.documentNumber, passenger.documentVerificationNumber), {
				shouldDirty: true,
			});
		} else {
			setValue('documentNumber', passenger.documentNumber, { shouldDirty: true });
		}

		//setValue('documentVerificationNumber', passenger.documentVerificationNumber, { shouldDirty: true });
		setValue('seatPreference', passenger.seatPreference, { shouldDirty: true });

		//@ts-ignore
		setIsPhysicalPerson(passenger.isPhysicalPerson);
		// @ts-ignore
		setInvoiceable(passenger.invoiceable);
	}, [passenger, setValue, theCountry]);

	useEffect(() => {
		const fetchTaxData = async () => {
			const result = await netsuiteTaxData();
			setTaxData(result.data);
		};

		fetchTaxData();
	}, [setTaxData]);

	useEffect(() => {
		const fetch = async () => {
			const { data } = await fetchAirlineCodes();
			if (data) {
				setAirlines(data);
			}
		};
		fetch();
	}, [setAirlines]);

	const doesNotApplyCountryCascadingFields =
		places && String(getNsCountryId(places, theCountry)) !== String(watchCountry);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleSavePassenger(data as IPassengerForm);
				})}
			>
				<Grid container spacing={2} className={classes.card}>
					<Grid item xs={12} sm={3}>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Button onClick={toggleModal} startIcon={<PersonSearchIcon />} variant="outlined" fullWidth>
								<Typography className={classes.title}>BUSCAR PAX</Typography>
							</Button>
							{openModal && (
								<PassagerModal
									open={openModal}
									handleClose={toggleModal}
									handleOptionSelection={handleOptionSelection}
									isPhysicalPerson={isPhysicalPerson}
								/>
							)}
						</Grid>
						{Object.keys(paxFormFields).map((key) => {
							const renderOption = getFieldRuleOption(key, theCountry, isPhysicalPerson, invoiceable);
							let selectOptions: Option[] = [];
							// @ts-ignore
							const forceCedula = key === 'documentNumber' && watchDocumentType === '1';

							if (getFieldType(key, theCountry) === renderOptions.select) {
								if (key === 'state') {
									if (doesNotApplyCountryCascadingFields) return null;
									selectOptions = getState(theCountry, places);
								}

								if (key === 'province') {
									if (doesNotApplyCountryCascadingFields) return null;
									selectOptions = getProvinces(String(watchState), places);
								}

								if (key === 'municipality') {
									if (doesNotApplyCountryCascadingFields) return null;
									selectOptions = getMunicipalities(watchProvince, places);
								}

								if (key === 'countryId') {
									selectOptions = places ? places.countries.map((c) => ({ id: String(c.id), name: c.name })) : [];
								}

								if (key === 'taxPayerTypeId') {
									selectOptions = getTaxData(theCountry, taxData?.taxPayerTypes);
								}

								if (key === 'taxRegimeId') {
									selectOptions = getTaxData(theCountry, taxData?.taxRegimes);
								}

								if (key === 'taxResidenceTypeId') {
									selectOptions = getTaxData(theCountry, taxData?.taxResidenceTypes);
								}

								if (key === 'fiscalResponsibilityId') {
									selectOptions = getTaxData(theCountry, taxData?.fiscalResponsibilities);
								}

								if (key === 'documentType') {
									selectOptions = getTaxData(theCountry, taxData?.documentTypes, invoiceable, isPhysicalPerson);
								}
							}

							return renderOption !== fieldRuleOptions.noshow ? (
								<Grid item xs={12} sm={11} className={classes.data}>
									{getPaxField(
										classes,
										errors,
										control,
										key,
										theCountry,
										isPhysicalPerson,
										invoiceable,
										selectOptions,
										forceCedula,
									)}
								</Grid>
							) : null;
						})}

						<Grid item xs={12} sm={11} className={classes.data}>
							<Typography className={classes.title}>Preferencia de asiento</Typography>
							<Controller
								name="seatPreference"
								control={control}
								render={({ field }) => (
									<TextField variant="outlined" fullWidth size="small" className={classes.textField} {...field} />
								)}
							/>
						</Grid>

						{taxData && watchDocumentType && doesDocumentNeedVerification(taxData, String(watchDocumentType)) && (
							<Grid item xs={12} sm={11} className={classes.data}>
								<Typography className={classes.title}>
									Verification Number <span style={{ color: 'red' }}>*</span>
								</Typography>
								<Controller
									// @ts-ignore
									name="documentVerificationNumber"
									control={control}
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
									}}
									render={({ field }) => (
										<TextField
											variant="outlined"
											required
											fullWidth
											size="small"
											className={classes.textField}
											helperText={checkShowErrorMessage(
												Boolean(errors['documentVerificationNumber']),
												errors['documentVerificationNumber']?.message,
											)}
											error={Boolean(errors['documentVerificationNumber'])}
											{...field}
										/>
									)}
								/>
							</Grid>
						)}
					</Grid>
					<Divider orientation="vertical" flexItem sx={{ my: 5, mx: 2 }} />
					<Grid item xs={12} sm={8.5}>
						<Grid container xs={12} sm={12} sx={{ pb: 1, justifyContent: 'space-between' }}>
							<Box display="flex" alignItems="center">
								<Typography>Persona Jurídica</Typography>
								<FormControlLabel
									control={<Switch checked={isPhysicalPerson} onClick={toggleIsPhysicalPerson} />}
									label="Persona Física"
									labelPlacement="end"
									sx={{ ml: 2 }}
								/>
							</Box>
							<Box display="flex" justifyContent="space-between" width="100%" gap={2}>
								{isPhysicalPerson ? <ControlledCheckBox name="mainPassenger" label="Pasajero Principal" /> : <div />}
								{isPhysicalPerson && (
									<FormControlLabel
										control={<Checkbox checked={invoiceable} onClick={toggleInvoiceable} />}
										label="Facturable"
									/>
								)}

								<Box display="flex" gap={2}>
									<Button variant="contained" color="success" type="submit">
										<SaveIcon />
									</Button>
									<Button variant="contained" color="error" onClick={handleRemovePax}>
										<DeleteIcon />
									</Button>
								</Box>
							</Box>
						</Grid>

						<Divider />
						{!isPhysicalPerson && (
							<Grid
								container
								xs={12}
								sm={12}
								direction="column"
								sx={{ pb: 1, justifyContent: 'space-between', marginTop: 2 }}
							>
								<Typography variant="h6" fontSize={16} fontWeight={800} sx={{ ml: 1 }}>
									Persona Jurídica:
								</Typography>
								<Typography variant="subtitle1" fontSize={12} sx={{ ml: 1 }}>
									Una persona jurídica se refiere a una entidad creada y reconocida por la ley con derechos y
									obligaciones propias, distinta de las personas físicas que la componen.
								</Typography>
								<Typography variant="subtitle1" fontSize={12} sx={{ ml: 1 }}>
									{
										'Este tipo de "pasajero" en el contexto de órdenes de servicio, representa a una empresa o corporación que se responsabiliza por la facturación de la orden, pero no participa directamente en los servicios del viaje, como vuelos o reservas hoteleras.'
									}
								</Typography>
								<Typography variant="subtitle1" fontSize={12} sx={{ ml: 1 }}>
									Cuando se agrega una persona jurídica, se debe entender que es la entidad a la que se le emitirá la
									factura y no un individuo que requiera servicios de viaje o documentos asociados a estos. La
									información relevante sobre esta persona jurídica como nombre de la empresa, dirección fiscal y número
									de identificación tributaria debrán ser cargados en la columna de la izquierda.
								</Typography>
								<Typography variant="subtitle1" fontSize={12} sx={{ ml: 1 }}>
									<strong>OBSERVACIONES:</strong> Recordá verificar si la empresa existe ya en nuestra base de datos
									utilizando la opción Buscar Pax
								</Typography>
							</Grid>
						)}

						{isPhysicalPerson && (
							<Grid
								container
								xs={12}
								sm={12}
								direction="column"
								sx={{ pb: 1, justifyContent: 'space-between', marginTop: 2 }}
							>
								<Typography variant="h6" fontSize={16} fontWeight={800} sx={{ ml: 1 }}>
									Documentos del viajero:
								</Typography>
								<Typography variant="subtitle1" fontSize={12} sx={{ ml: 1 }}>
									Documentos con los cuales vamos a gestionar todas las reservas de este pasajero.
								</Typography>
							</Grid>
						)}
						{isPhysicalPerson &&
							documents.map((document, docIndex) => (
								<Grid container spacing={2} key={docIndex} className={classes.data}>
									<Grid item xs={12} sm={4}>
										{/* 
								// @ts-ignore */}
										<input type="hidden" {...register(`documents.${docIndex}.keyId`)} />
										<Typography className={classes.title}>Tipo</Typography>
										<ControlledSelect
											name={`documents.${docIndex}.documentType`}
											rules={{
												required: { value: true, message: 'Este campo es requerido' },
											}}
											options={passengerDocumentType.map((item) => ({ id: item.id, name: item.name }))}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Typography className={classes.title}>Origen</Typography>
										<ControlledSelect
											name={`documents.${docIndex}.country`}
											rules={{
												required: { value: true, message: 'Este campo es requerido' },
											}}
											options={typeNationalites.map((item) => ({ id: item.flagsCode.toString(), name: item.name }))}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Typography className={classes.title}>Numero</Typography>
										<Controller
											name={`documents.${docIndex}.documentNumber` as const}
											control={control}
											rules={{
												required: { value: true, message: 'Este campo es requerido' },
											}}
											render={({ field }) => (
												<TextField
													variant="outlined"
													required
													fullWidth
													size="small"
													InputLabelProps={{ shrink: true }}
													className={classes.textField}
													helperText={checkShowErrorMessage(
														Boolean(errors.documents?.[docIndex]?.documentNumber),
														errors.documents?.[docIndex]?.documentNumber?.message,
													)}
													error={Boolean(errors.documents?.[docIndex]?.documentNumber)}
													{...field}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Typography className={classes.title}>Expiración</Typography>
										<ControlledDatePicker
											name={`documents.${docIndex}.expirationDate`}
											rules={{ required: 'Este campo es requerido' }}
											required={true}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Typography className={classes.title}>Imagen</Typography>
										<ImageUploader
											initialPreview={documents[docIndex].image}
											onImageUpload={handleImageUpload(docIndex)}
										/>
										{/* <DragDrop
                                index={index}
                                docIndex={docIndex}
                                updateImage={updateImage}
                                documentsState={documentsState}
                            /> */}
									</Grid>
									<Grid xs={12} sm={12}>
										<Box display="flex" alignItems="flex-end" width="100%" flexDirection="row-reverse" gap={1}>
											{/* <VisibilityIcon
											sx={{
												cursor: 'pointer',
												color: theme.palette.secondary.main,
											}}
											onClick={() => document.image && window.open(document.image)}
										/> */}

											<Button variant="contained" color="error" onClick={handleDeleteDocument(docIndex)}>
												Remover Documento
											</Button>
										</Box>
									</Grid>
								</Grid>
							))}

						{isPhysicalPerson && (
							<Grid item xs={12} sm={12} marginBottom={2}>
								<Button className={classes.buttonAdd} variant="outlined" onClick={handleAddDocument}>
									+ Agregar documento
								</Button>
							</Grid>
						)}
						<Divider />
						{isPhysicalPerson && (
							<Grid container xs={12} sm={12} sx={{ pb: 1, justifyContent: 'space-between', marginTop: 2 }}>
								<Typography variant="h6" fontSize={16} fontWeight={800} sx={{ ml: 1 }}>
									Viajero frecuente:
								</Typography>
							</Grid>
						)}
						{isPhysicalPerson &&
							ffNumbers.map((ff, ffIndex) => {
								return (
									<Grid container spacing={2} key={ffIndex} className={classes.data}>
										<Grid item xs={12} sm={4}>
											<Typography className={classes.title}>Airline</Typography>
											<ControlledSelect
												name={`frequentFlyerNumbers.${ffIndex}.airlineCode`}
												rules={{
													required: { value: true, message: 'Este campo es requerido' },
												}}
												options={airlines
													.map((item) => ({ id: item.code, name: `${item.code} - ${item.name}` }))
													.sort((a, b) => a.id.localeCompare(b.id))}
											/>
										</Grid>
										<Grid item xs={12} sm={4}>
											<Typography className={classes.title}>Numero</Typography>
											<Controller
												name={`frequentFlyerNumbers.${ffIndex}.frequentFlyerNumber` as const}
												control={control}
												rules={{
													required: { value: true, message: 'Este campo es requerido' },
												}}
												render={({ field }) => (
													<TextField
														variant="outlined"
														required
														fullWidth
														size="small"
														InputLabelProps={{ shrink: true }}
														className={classes.textField}
														helperText={checkShowErrorMessage(
															Boolean(errors.frequentFlyerNumbers?.[ffIndex]?.frequentFlyerNumber),
															errors.frequentFlyerNumbers?.[ffIndex]?.frequentFlyerNumber?.message,
														)}
														error={Boolean(errors.frequentFlyerNumbers?.[ffIndex]?.frequentFlyerNumber)}
														{...field}
													/>
												)}
											/>
										</Grid>
										<Grid xs={12} sm={2}>
											<Box
												display="flex"
												alignItems="flex-end"
												width="100%"
												height="100%"
												flexDirection="row-reverse"
												gap={1}
											>
												<Button variant="contained" color="error" onClick={handleDeleteFF(ffIndex)}>
													Remover
												</Button>
											</Box>
										</Grid>
									</Grid>
								);
							})}

						{isPhysicalPerson && (
							<Grid item xs={12} sm={12} marginBottom={2}>
								<Button className={classes.buttonAdd} variant="outlined" onClick={handleAddFF}>
									+ Agregar numero de viajero frequente
								</Button>
							</Grid>
						)}
						<Divider />
						<Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} mt={4} width="100%">
							<Typography variant="body2">Estado de sincronización:</Typography>
							{netsuiteSyncStatus && <NetsuiteEntitySyncStatus syncStatus={netsuiteSyncStatus} />}
						</Box>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};
