import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Layout } from 'features/common/components/Layout';
import { SalesOrderList } from '../../features/salesOrder/components/SaleOrderList';

export const SalesOrderListPage = (): JSX.Element => {
	return (
		<Layout>
			<Grid container paddingY={5} justifyContent={'center'}>
				<Grid
					item
					xs={12}
					sx={{
						ml: 20,
					}}
				>
					<Typography variant="h4" style={{ fontWeight: 500 }}>
						Ordenes de venta
					</Typography>
				</Grid>
				<Grid paddingY={5}>
					<SalesOrderList />
				</Grid>
			</Grid>
		</Layout>
	);
};
