import React, { useState, useCallback, useRef } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, AlertColor, Alert, Snackbar, Grid, Typography, Autocomplete, TextField } from '@mui/material';

import { extractErrorMessage } from 'features/quotation/helpers';
import { getAutocompletePassenger } from 'features/salesOrder/services';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleOptionSelection: (passengerId: string) => void;
	isPhysicalPerson?: boolean;
}
interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export function PassagerModal({ open, handleClose, handleOptionSelection, isPhysicalPerson }: Props): JSX.Element {
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [optionsModal, setOptionsModal] = useState<any[]>([]);
	const [values, setValues] = React.useState<{ passengerId?: string } | null>(null);
	const [inputValue, setInputValue] = useState('');
	const [isOptionSelected, setIsOptionSelected] = useState(false);
	const autocompleteDebouncer = useRef<number | null>(null);

	const style = {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 700,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const fetchOptions = useCallback(
		async (valueToSearch): Promise<{ passengerId: string; name: string; lastName: string }[]> => {
			try {
				const response = await getAutocompletePassenger(valueToSearch);
				const selectOption = response.data
					.filter((d) => d.isPhysicalPerson === Boolean(isPhysicalPerson))
					.map((option) => ({
						passengerId: option.passengerId,
						name: option.name,
						lastName: option.lastName,
						documents: option.documents,
						documentType: option.documents.map((doc) => doc.documentType),
						documentNumber: option.documents.map((doc) => doc.documentNumber),
						isPhysicalPerson: option.isPhysicalPerson,
					}));
				return selectOption;
			} catch (error) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al seleccionar'),
				});
				return [];
			}
		},
		[inputValue, optionsModal],
	);
	const inputChangeHandler = async (value: string) => {
		setInputValue(value);
		if (autocompleteDebouncer.current !== null) {
			clearTimeout(autocompleteDebouncer.current);
		}

		if (value.length >= 4) {
			autocompleteDebouncer.current = setTimeout(async () => {
				try {
					const results = await fetchOptions(inputValue);
					setOptionsModal(results);
				} catch (error) {
					setAlert({
						show: true,
						severity: 'error',
						message: extractErrorMessage(error, 'Ocurrió un error al completar los datos'),
					});
				}
				300;
			});
		} else {
			setOptionsModal([]);
		}
	};

	return (
		<>
			<Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
				<Box sx={{ ...style, textAlign: 'center' }}>
					<Button sx={{ ml: 80, p: 0, width: 5 }} onClick={handleClose}>
						X
					</Button>
					<Typography marginBottom={1}> Buscá un pax x nombre y/o documento:</Typography>
					<Typography marginBottom={3} fontSize={12} color="error">
						Importante: Puedes actualizar los datos del pasajero seleccionado, pero no debes modificar su identidad
						(nombre o documento de facturación), ya que está asociada a otras órdenes de venta.
					</Typography>
					<Grid container sx={{ justifyContent: 'center' }}>
						<Autocomplete
							getOptionLabel={(option) =>
								option === 'string'
									? option
									: [
											`${option.name} ${option.lastName} `,
											...(option.documents && option.documents.length > 0
												? option.documents
														.map((doc: any) => {
															let documentLabel = '';
															switch (doc.documentType) {
																case 'Passport':
																	documentLabel = 'Pasaporte';
																	break;
																case 'DriverLicense':
																	documentLabel = 'Libreta de Conducir';
																	break;

																default:
																	documentLabel = doc.documentType;
															}
															return `${documentLabel}: ${doc.documentNumber}`;
														})
														.join(', ')
												: []),
									  ]
											.filter(Boolean)
											.join('')
							}
							filterOptions={(x) => x}
							options={optionsModal}
							autoComplete
							includeInputInList
							filterSelectedOptions
							value={values}
							onChange={(_, newValue) => {
								setValues(newValue);
								setIsOptionSelected(!!newValue);
							}}
							onInputChange={async (_, newValue) => {
								inputChangeHandler(newValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									autoFocus={true}
									variant="outlined"
									fullWidth
									size="small"
									InputLabelProps={{ shrink: true }}
									sx={{ width: 500, pb: 3 }}
								/>
							)}
						/>
					</Grid>
					<Button
						variant="contained"
						color="success"
						onClick={() => {
							if (isOptionSelected && values?.passengerId) {
								handleOptionSelection(values.passengerId);
								handleClose();
							}
						}}
					>
						Cargar pasajero
					</Button>
				</Box>
			</Modal>
			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
