import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

import { dashIfNone, formatDateTimeToShowUser } from 'features/common/helpers';
import { IChannel, IPatchSaleOrder, ISaleOrderDetail, ITransaction, segmentTypes } from 'features/salesOrder/types';
import {
	getChannels,
	getVariationLogs,
	patchSaleOrder,
	getPaymentReceivedTransactions,
	addToQueueToSyncWithNetsuite,
} from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import NetsuiteEntitySyncStatus from '../../../Netsuite/components/NetsuiteEntitySyncStatus';
import { extractErrorMessage } from '../../../../../features/quotation/helpers';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { isOperatorOrHigher } from '../../../../../features/auth/helpers';
import DatosDeLaVenta from './DatosDeLaVenta';

interface Props {
	data: ISaleOrderDetail;
	onSetAlert: (values: ShowAlertState) => void;
	onUpdate: () => void;
	setLoading: (loading: boolean) => void;
}

const formatDate = (value: string) => {
	// "2024-04-18"
	const arr = value.split('-');
	return `${arr[2]}/${arr[1]}/${arr[0]}`;
};

export interface LogItem {
	id: string;
	saleOrderId: string;
	saleAmount: number;
	saleAmountVariation: number;
	totalCost: number;
	totalCostVariation: number;
	grossProfit: number;
	grossProfitVariation: number;
	dateCreated: string;
}

// @ts-ignore
const truncate = (str, maxLength) => {
	return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str;
};

// @ts-ignore
const getServiceDescription = (service) => {
	if (!service || !service.serviceType) {
		return truncate('No especificado', 60);
	}
	let description;
	switch (service.serviceType) {
		case 'Accommodation': {
			const destinationCode = service.destination?.code ?? 'N/A';
			const serviceProviderName = service.serviceProvider?.name ?? 'N/A';
			const roomsLength = service.rooms?.length ?? 0;
			description = `Alojamiento | ${destinationCode}: ${serviceProviderName} | ${roomsLength} hab`;
			break;
		}
		case 'Flight': {
			const vcCode = service.vc?.code ?? 'N/A';
			const reference = service.reference ?? 'N/A';
			const passengerIdsLength = service.passengerIds?.length ?? 0;
			description = `Vuelo | vc: ${vcCode} | pnr: ${reference} | ${passengerIdsLength} pax`;
			break;
		}
		case 'Other': {
			const otherServiceType = service.otherServiceType ?? 'Otro';
			const serviceName = service.serviceName ?? 'N/A';
			const passengerIdsLength = service.passengerIds?.length ?? 0;
			description = `${otherServiceType}: ${serviceName} | ${passengerIdsLength} pax`;
			break;
		}
		case 'Transfer': {
			const transferSegment = service.transferSegments?.[0];
			const transferType = transferSegment?.transferType ?? 'N/A';
			const originName = transferSegment?.originName ?? 'N/A';
			const destinationName = transferSegment?.destinationName ?? 'N/A';
			const passengerIdsLength = service.passengerIds?.length ?? 0;
			description = `Traslados ${transferType} ${originName} ${destinationName} | ${passengerIdsLength} pax`;
			break;
		}
		default: {
			description = service.serviceType ?? 'No especificado';
			break;
		}
	}
	return truncate(description, 50);
};

// @ts-ignore
const getEnableStatus = (service) => {
	return service.enable ? 'SI' : 'NO';
};

// @ts-ignore
const capitalizeWords = (str) => {
	// @ts-ignore
	return str ? str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : '';
};

export const General = ({ data, onSetAlert, onUpdate, setLoading }: Props): JSX.Element => {
	const { id: saleOrderId } = useParams<{ id: string }>();
	const [variationData, setVariationData] = useState<LogItem[]>([]);
	const [channels, setChannels] = useState<IChannel[]>([]);
	const [transactions, setTransactions] = useState<ITransaction[]>([]);
	const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);
	const [transactionsError, setTransactionsError] = useState<string | null>(null);
	const [isEditingClientReference, setIsEditingClientReference] = useState(false);
	const [clientReference, setClientReference] = useState(data.clientReference || '');
	const clientReferenceTextFieldRef = useRef(null);
	useEffect(() => {
		const fetchLogs = async () => {
			try {
				const { data: result } = await getVariationLogs(saleOrderId);
				setVariationData(result);
			} catch (e) {
				console.log(e);
			}
		};

		fetchLogs();
	}, []);

	useEffect(() => {
		const fetchTransactions = async () => {
			try {
				const { data: transactionsData } = await getPaymentReceivedTransactions(saleOrderId);
				setTransactions(transactionsData);
			} catch (e) {
				setTransactionsError('Error fetching transactions');
			} finally {
				setLoadingTransactions(false);
			}
		};

		fetchTransactions();
	}, [saleOrderId]);

	const mainPassenger = data.passengers.find((passenger) => passenger.mainPassenger);
	const nameAndLastName = mainPassenger ? `${mainPassenger.name} ${mainPassenger.lastName}` : 'No definido';
	const passengerCount = data?.passengers?.length ?? 0;
	const services = data.services;

	const handleChangeSegmentType = (event: SelectChangeEvent) => {
		handleRequest({
			segmentType: event.target.value as string,
		});
	};

	const handleChangeChannel = (event: SelectChangeEvent) => {
		handleRequest({
			channelId: Number(event.target.value),
		});
	};

	const handleRequest = async (data: IPatchSaleOrder) => {
		try {
			setLoading(true);
			await patchSaleOrder(saleOrderId, data);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Salesorder actualizada',
			});
			onUpdate();
		} catch (e) {
			// @ts-ignore
			alert(e.response.data.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const loadChannels = async () => {
			try {
				const response = await getChannels();
				setChannels(response.data);
			} catch (e: any) {
				console.error(e);
			}
		};

		loadChannels();
	}, []);

	const handleChangeClientReference = (event: React.ChangeEvent<HTMLInputElement>) => {
		setClientReference(event.target.value);
	};

	const handleSaveClientReference = async () => {
		setIsEditingClientReference(false);
		await handleRequest({ clientReference: clientReference });
	};

	const handleEditClientReferenceClick = () => {
		setIsEditingClientReference(true);
		setTimeout(() => {
			if (clientReferenceTextFieldRef && clientReferenceTextFieldRef.current) {
				// @ts-ignore
				clientReferenceTextFieldRef?.current?.focus();
			}
		}, 100);
	};

	const handleKeyPressClientReferenceField = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && (data.clientReference || '') !== clientReference) {
			e.preventDefault();
			handleSaveClientReference();
		}
	};

	// const handleChangeAutomaticSyncWithNetsuite = async (event: React.ChangeEvent<HTMLInputElement>) =>
	// 	await handleRequest({ automaticSyncWithNetsuite: event.target.checked });

	const handleAddToQueueToSyncWithNetsuite = async () => {
		try {
			setLoading(true);
			await addToQueueToSyncWithNetsuite(saleOrderId);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Salesorder agregada a cola de sincronización con Netsuite satisfactorimante',
			});
			onUpdate();
		} catch (error) {
			onSetAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(
					error,
					'Ocurrió un error al agregar la salesorder a la cola de sincronización con Netsuite',
				),
			});
		} finally {
			setLoading(false);
		}
	};

	const { auth } = useSelector((state: RootState) => state);

	const [modalOpen, setModalOpen] = useState(false);
	const [switchChecked, setSwitchChecked] = useState(data.automaticSyncWithNetsuite);

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
		if (!switchChecked) {
			setSwitchChecked(true);
		}
	};

	const handleConfirmModal = () => {
		setSwitchChecked(false);
		handleSyncChange(false);
		handleCloseModal();
	};

	const handleSyncChange = async (isEnabled: boolean) => {
		await handleRequest({ automaticSyncWithNetsuite: isEnabled });
	};

	const handleChangeAutomaticSyncWithNetsuite = async (event: { target: { checked: any } }) => {
		const { checked } = event.target;
		if (!checked) {
			handleOpenModal();
		} else {
			setSwitchChecked(true);
			handleSyncChange(true);
		}
	};

	return (
		<Grid container pl={3} mt={2} spacing={2} flexDirection="row">
			<Grid item xs={9} flexDirection="row">
				<Box gap={2} display="flex">
					<Grid item xs={7} flexDirection="row">
						<Card elevation={4} sx={{ width: '100%' }}>
							<CardContent
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 1,
								}}
							>
								<Typography variant="h6">Orden de ventas</Typography>

								<Box display="flex" gap={1}>
									<Typography variant="body2" sx={{ width: '100px' }}>
										Creada:
									</Typography>
									<Typography variant="body2">
										{data.created ? formatDateTimeToShowUser(data.created).substring(0, 10) : '-'}
									</Typography>
									<Typography variant="body2" style={{ fontWeight: 'bold' }}>
										por {data.createdBy}
									</Typography>
								</Box>

								<Box display="flex" gap={1}>
									<Typography variant="body2" sx={{ width: '100px' }}>
										Modificada:
									</Typography>
									<Typography variant="body2">
										{' '}
										{data.updated ? formatDateTimeToShowUser(data.updated).substring(0, 10) : ''}{' '}
									</Typography>
									<Typography variant="body2" style={{ fontWeight: 'bold' }}>
										{' '}
										{data.updatedBy ? `por ${data.updatedBy}` : ''}
									</Typography>
								</Box>

								<Box alignItems="center" display="flex" gap={1}>
									<Tooltip title='LEISURE: todas las oórdes exceptuando aquellas que que provienen de clientes de tipo empresa que CUENTAN CON UN ACUERDO CORPORATIVO ACTIVO. Estas ultimas podran ser de tipo CORPO o MAYORISTA. Nota: una orden para las vacaciones de "Pepe" que es de la empresa "PEPITO S.A." y que no será facturada a la misma porque se trata de una compra personal, es una orden de tipo "LEISURE"'>
										<Typography variant="body2" sx={{ width: '100px' }}>
											Segmento:
										</Typography>
									</Tooltip>
									<Select
										fullWidth
										size="small"
										value={segmentTypes.find((st) => st.id === data.segmentType)?.id}
										onChange={handleChangeSegmentType}
									>
										{segmentTypes.map((segmentType) => {
											return (
												<MenuItem key={segmentType.id} value={segmentType.id}>
													{segmentType.name}
												</MenuItem>
											);
										})}
									</Select>
								</Box>

								<Box alignItems="center" display="flex" gap={1}>
									<Tooltip title="El canal es definido en base a como llegó esta venta a nosotros. LEAD: por default es nuestro canal, las ordenes originadas por un cliente potencial que mostró interés mediante una campaña o contacto inicial. | REFERIDO: para las ordenes provenientes de una recomendación de clientes actuales, socios comerciales, un amigo, familia, etc. | FULL-ONLINE: Ordenes originadas por ventas en la web. | OFICINA: Aquellas ordenes priginadas pro clientes que entraron a nuestras oficinas a consultarnos y compraron.">
										<Typography variant="body2" sx={{ width: '100px' }}>
											Canal:
										</Typography>
									</Tooltip>
									{/* 
								// @ts-ignore */}
									<Select fullWidth size="small" value={data.channel.id} onChange={handleChangeChannel}>
										{channels.map((channel) => {
											return (
												<MenuItem key={channel.id} value={channel.id}>
													{channel.name}
												</MenuItem>
											);
										})}
									</Select>
									{/* <Typography variant="body2">{data.channel.name}</Typography> */}
								</Box>

								<Box alignItems="center" display="flex" gap={1}>
									<Tooltip title="Este campo es mayormente utilizado para las ordenes del segmento corporativo o mayorista. Aquí colocamos la referencia provista por nuestros cleintes, por ejemplo su identificador de venta o su orden de compra">
										<Typography variant="body2" sx={{ width: '115px' }}>
											Referencia de cliente:
										</Typography>
									</Tooltip>
									<TextField
										fullWidth
										onKeyPress={handleKeyPressClientReferenceField}
										inputRef={clientReferenceTextFieldRef}
										disabled={!isEditingClientReference}
										value={clientReference}
										size="small"
										onChange={handleChangeClientReference}
										inputProps={{ maxLength: 255 }}
									/>
									<IconButton
										color="primary"
										disabled={isEditingClientReference && (data.clientReference || '') === clientReference}
										onClick={isEditingClientReference ? handleSaveClientReference : handleEditClientReferenceClick}
									>
										{isEditingClientReference ? <SaveIcon /> : <EditIcon />}
									</IconButton>
								</Box>
								<Box display="flex" gap={1}>
									<Typography variant="body2" sx={{ width: '100px' }}>
										Pax principal:
									</Typography>
									<Typography variant="body2">{capitalizeWords(nameAndLastName)}</Typography>
								</Box>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={5} flexDirection="row">
						<Box display="flex" flexDirection="column" gap={2}>
							<Card elevation={4} sx={{ width: '100%' }}>
								<CardContent>
									<Typography variant="h6">Detalle del viaje</Typography>
									<Box display="flex" gap={1}>
										<Typography variant="body2">Destino/s:</Typography>
										<Typography variant="body2"> {dashIfNone(data.destinations)} </Typography>
									</Box>
									<Box display="flex" gap={1}>
										<Typography variant="body2">Fecha de comienzo:</Typography>
										<Typography variant="body2">
											{data.travelDepartureDate ? formatDate(data.travelDepartureDate) : '-'}
										</Typography>
									</Box>
									<Box display="flex" gap={1}>
										<Typography variant="body2">Fecha de finalización:</Typography>
										<Typography variant="body2">
											{data.travelReturnDate ? formatDate(data.travelReturnDate) : '-'}
										</Typography>
									</Box>
									<Box display="flex" gap={1}>
										<Typography variant="body2">Cantidad de pax:</Typography>
										<Typography variant="body2">{passengerCount}</Typography>
									</Box>
								</CardContent>
							</Card>
							<Card elevation={4} sx={{ width: '100%' }}>
								<CardContent>
									<Box alignItems="center" display="flex" gap={1} justifyContent={'space-between'}>
										<Typography variant="h6">Sincro:</Typography>
										<NetsuiteEntitySyncStatus syncStatus={data.syncStatus} />
									</Box>
									<Box alignItems="center" display="flex" gap={1} justifyContent={'space-between'}>
										<Tooltip title="ACTIVO: A media noche la SaleOrder será enviada a una cola de sincronización con Netsuite, esto implica que la orden será reflejada en la contabilidad y será FACTURADA al cliente. | INACTIVO: LA orden no será reflejada en el sistema contable y NO será facturada">
											<Typography variant="body2">Sincronizar automáticamente con Netsuite :</Typography>
										</Tooltip>
										<Switch checked={switchChecked} onChange={handleChangeAutomaticSyncWithNetsuite} color="primary" />
									</Box>
									{isOperatorOrHigher(auth.user) && (
										<Box alignItems="center" display="flex" gap={1} marginTop={1} justifyContent={'space-between'}>
											<Tooltip title="Enviará la SaleOrder a una cola de sincronización con Netsuite para realizar el proceso ASAP.">
												<Button
													variant="outlined"
													color="primary"
													title="Sincronizar con Netsuite"
													onClick={handleAddToQueueToSyncWithNetsuite}
													sx={{
														height: 20,
														minHeight: '20px',
													}}
												>
													Sincronizar ahora
												</Button>
											</Tooltip>
										</Box>
									)}
								</CardContent>
							</Card>
						</Box>
						<Dialog
							open={modalOpen}
							onClose={handleCloseModal}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">{'Confirmar Desactivación'}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									¿Desactivar la sincronización con Netsuite? Esto impedirá la facturación y contabilización automática
									de la orden al finalizar el dia.
								</DialogContentText>
								<DialogContentText id="alert-dialog-description">
									Desactivar mientras trabajas en la orden puede ser prudente, pero reactivar a tiempo es crucial para
									su procesamiento.
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button variant="outlined" onClick={handleCloseModal}>
									Cancelar
								</Button>
								<Button variant="outlined" onClick={handleConfirmModal} autoFocus>
									Aceptar
								</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				</Box>
				<Box mt={2}>
					<Card elevation={4}>
						<CardContent>
							<TableContainer>
								<Typography variant="h6">Servicios de la orden</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Tipo de Servicio</TableCell>
											<TableCell>Precio de venta</TableCell>
											<TableCell>Costo</TableCell>
											<TableCell>Activo</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{services.map((service, index) => (
											<TableRow key={index}>
												{/* 
													//@ts-ignore */}
												<TableCell>{service ? getServiceDescription(service) : 'Descripción no disponible'}</TableCell>
												<TableCell>
													{/* 
													//@ts-ignore */}
													{service.currency} {service.totalSaleAmount ?? 'No especificado'}
												</TableCell>

												<TableCell>
													{/* 
													//@ts-ignore */}
													{service.currency} {service.totalCost ?? 'No especificado'}
												</TableCell>
												<TableCell>
													{/* 
													//@ts-ignore */}
													{service ? getEnableStatus(service) : 'Status no disponible'}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</CardContent>
					</Card>
				</Box>
				<Box mt={2}>
					<Card elevation={4}>
						<CardContent>
							<TableContainer>
								<Typography variant="h6">Modificaciones</Typography>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell align="left">Fecha</TableCell>
											<TableCell align="right">Venta</TableCell>
											<TableCell align="right">modi (V)</TableCell>
											<TableCell align="right">Costo</TableCell>
											<TableCell align="right">modi (C)</TableCell>
											<TableCell align="right">Margen</TableCell>
											<TableCell align="right">modi (M)</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{variationData.map((row, index) => (
											<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell align="left">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														{formatDate(row.dateCreated)}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.saleAmount}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.saleAmountVariation}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.totalCost}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.totalCostVariation}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.grossProfit}
													</Typography>
												</TableCell>
												<TableCell align="right">
													<Typography variant="subtitle2" sx={{ fontSize: '0.75rem' }}>
														USD {row.grossProfitVariation}
													</Typography>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</CardContent>
					</Card>
				</Box>
			</Grid>
			<Grid item xs={3}>
				<DatosDeLaVenta
					saleAmount={data.saleAmount}
					totalCost={data.totalCost}
					grossProfitPercentage={data.grossProfitPercentage}
					grossProfit={data.grossProfit}
					chargedAmount={data.chargedAmount}
					pendingPayAmount={data.pendingPayAmount}
					travelDepartureDate={data.travelDepartureDate}
					currency={data.currency}
				/>
				<Box mt={2}>
					<Card elevation={4}>
						<CardContent>
							<Typography variant="h6">Cobros</Typography>
							{loadingTransactions ? (
								<Typography>Cargando transacciones...</Typography>
							) : transactionsError ? (
								<Typography>{transactionsError}</Typography>
							) : (
								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Fecha</TableCell>
												<TableCell>Monto</TableCell>
												<TableCell>Tipo de Relación</TableCell>
												<TableCell>Tipo de Plataforma</TableCell>
												<TableCell>Observaciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{transactions.map((transaction) => (
												<TableRow key={transaction.transactionId}>
													<TableCell>{transaction.createdAt}</TableCell>
													<TableCell>{transaction.amountInMainCurrency}</TableCell>
													<TableCell>{transaction.relationType}</TableCell>
													<TableCell>{transaction.platformType}</TableCell>
													<TableCell>{dashIfNone(transaction.observations)}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							)}
						</CardContent>
					</Card>
				</Box>
			</Grid>
		</Grid>
	);
};
