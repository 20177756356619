import Cookies from 'js-cookie';

const TOKEN_KEY = 'Q_TOKEN';
const API_KEY = 'Q_API_KEY';
const MeVueloToken = 'M_TOKEN';
//const TOKEN_KEY = '28580003-a476-4430-8c99-7a70f8477dc1';

export class CookieService {
	static setToken = (token: string, hours: number): string | undefined =>
		Cookies.set(TOKEN_KEY, token, { expires: Math.floor(hours / 24) });

	static setMeVueloToken = (token: string): string | undefined => Cookies.set(MeVueloToken, token);

	static getToken = (): string | undefined => Cookies.get(TOKEN_KEY);

	static getMeVueloToken = (): string | undefined => Cookies.get(MeVueloToken);

	static clearToken = (): void => Cookies.remove(TOKEN_KEY);

	static setAgencies = (value: string): string | undefined => Cookies.set('Agencies', value);
	static getAgencies = (): string | undefined => Cookies.get('Agencies');
	static clearAgencies = (): void => Cookies.remove('Agencies');
}

export class SessionService {
	static setApiKey = (apiKey: string): void => {
		sessionStorage.setItem(API_KEY, apiKey);
	};

	static getApiKey = (): string | null => {
		return sessionStorage.getItem(API_KEY);
	};

	static clearApiKey = (): void => {
		sessionStorage.removeItem(API_KEY);
	};
}
