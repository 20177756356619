import axios, { AxiosResponse } from 'axios';
import endpoints from '../constants/endpoints';
import {
	AutocompleteContactData,
	OptionalService,
	QuotationInfoToSendClient,
	QuotationInfoToSendClientResponse,
	QuotationSnapShotToClient,
	QuotationSnapShotToClientDestinationMultiple,
	QuotationSnapShotToClientOnlyFlights,
	ServiceOption,
} from '../features/quotation/types';
import { CookieService, SessionService } from 'features/auth/services/CookieService';
import { HotelSuggestion } from '../features/quotation/components/HotelOptionsConfig';
import { defaultCountryWithAgencyConfig } from '../features/auth/constants';
import { ICodeName } from 'features/salesOrder/types';

export const ApiService = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	baseURL: process.env.REACT_APP_API_URL,
});

export const ApiSaleOrderService = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	baseURL: process.env.REACT_APP_SALES_ORDER_API_URL,
});

export const ApiHotelProxyService = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	baseURL: process.env.REACT_APP_HOTEL_PROXY_API_URL,
});

export const ApiMeVueloService = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	baseURL: process.env.REACT_APP_MEVUELO_API_URL,
});

export const ApiPackageService = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	baseURL: process.env.REACT_APP_PACKAGES_API_URL,
});

export const axiosDestinations = axios.create({
	baseURL: process.env.REACT_APP_DESTINATION_CMS_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});
ApiSaleOrderService.interceptors.request.use((config) => {
	const maybeApiKey = SessionService.getApiKey();
	if (maybeApiKey) config.headers['X-Api-Key'] = maybeApiKey;
	else config.headers['X-Api-Key'] = defaultCountryWithAgencyConfig.apiKey;
	const token = CookieService.getToken();
	if (token) {
		config.headers['Authorization'] = `Bearer ${token}`;
	}
	return config;
});

ApiService.interceptors.request.use((config) => {
	const maybeApiKey = SessionService.getApiKey();
	if (maybeApiKey) config.headers['X-Api-Key'] = maybeApiKey;
	else config.headers['X-Api-Key'] = defaultCountryWithAgencyConfig.apiKey;
	return config;
});

ApiHotelProxyService.interceptors.request.use((config) => {
	const maybeApiKey = SessionService.getApiKey();
	if (maybeApiKey) config.headers['X-Api-Key'] = maybeApiKey;
	else config.headers['X-Api-Key'] = defaultCountryWithAgencyConfig.apiKey;
	return config;
});

ApiPackageService.interceptors.request.use((config) => {
	const maybeApiKey = SessionService.getApiKey();
	if (maybeApiKey) config.headers['X-Api-Key'] = maybeApiKey;
	else config.headers['X-Api-Key'] = defaultCountryWithAgencyConfig.apiKey;
	return config;
});

ApiService.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			CookieService.clearToken();
			SessionService.clearApiKey();
			location.reload();
		} else throw error;
	},
);

ApiHotelProxyService.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			CookieService.clearToken();
			SessionService.clearApiKey();
			location.reload();
		}
	},
);

ApiPackageService.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			CookieService.clearToken();
			SessionService.clearApiKey();
			location.reload();
		}
	},
);
ApiMeVueloService.interceptors.request.use((config) => {
	const token = CookieService.getMeVueloToken();
	if (token) config.headers['Authorization'] = `Bearer ${token}`;
	return config;
});

export const fetcher = (url: string): Promise<AxiosResponse> => ApiService.get(url).then((res) => res.data);

export const fetcherPost = (url: string, payload: unknown): Promise<any> =>
	ApiService.post(url, payload).then((res) => res.data);

export const parseGDSAirRawData = async (gdsRawData: string): Promise<AxiosResponse> =>
	ApiService.post(endpoints.airParser, gdsRawData, {
		headers: { 'Content-Type': 'text/plain' },
	});

export const destinationSuggestion = async (value: string): Promise<AxiosResponse> =>
	ApiService.get(`${endpoints.destinationSuggestion}${value}`);

export const getOptionalServicesByDestination = async (cityCode: string): Promise<AxiosResponse<OptionalService[]>> =>
	ApiService.get(`${endpoints.optionalServicesByDestination.replace('[cityCode]', cityCode)}`);

export const hotelSuggestion = async (
	cityCode: string,
	queryText: string,
): Promise<AxiosResponse<HotelSuggestion[]>> => {
	const queryTextEncode = encodeURIComponent(queryText);
	const finalEndpoint = endpoints.hotelSuggestion
		.replace('[cityCode]', cityCode)
		.replace('[queryText]', queryTextEncode);
	return ApiService.get(finalEndpoint);
};

export const postServicesSuggestions = async (
	from: string,
	cityCodes: string[],
): Promise<AxiosResponse<ServiceOption[]>> =>
	ApiService.post(endpoints.servicesSuggestion, {
		from,
		destinations: cityCodes,
	});

export const postSendQuotationToClient = async (
	info: QuotationInfoToSendClient,
): Promise<AxiosResponse<QuotationInfoToSendClientResponse>> => ApiService.post(endpoints.quotationToClient, info);

export const findQuotationToClient = async (
	id: string,
): Promise<
	AxiosResponse<
		QuotationSnapShotToClient | QuotationSnapShotToClientDestinationMultiple | QuotationSnapShotToClientOnlyFlights
	>
> => ApiService.get(`${endpoints.quotationToClient}/${id}`);
export const findContactDataByDeal = async (dealId: number): Promise<AxiosResponse<AutocompleteContactData>> =>
	ApiService.get(`${endpoints.autocompleteContact.replace('[dealId]', String(dealId))}`);
export const findContactsDataByDealID = async (dealId: number): Promise<AxiosResponse<AutocompleteContactData[]>> =>
	ApiService.get(`${endpoints.contactsByDealId.replace('[dealId]', String(dealId))}`);

export const fetchAirlineCodes = async (): Promise<AxiosResponse<ICodeName[]>> => ApiService.get(endpoints.getAirlines);
