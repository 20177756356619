import { ServiceUnitEnum } from 'features/salesOrder/types';

type ConfigType = {
	[key: string]: {
		typeOptions: {
			name: string;
			value: string;
			defaultValues?: {
				sale?: number;
				cost?: number;
				markup?: number;
				providerName?: string;
				setAllPax?: boolean;
				providerId?: string;
				serviceUnit?: ServiceUnitEnum;
				feeServiceType?: string;
			};
		}[];
	};
};

export const config: ConfigType = {
	UY: {
		typeOptions: [
			{
				name: 'Fee administrativo',
				value: 'fee_admin_corpo',
				defaultValues: {
					sale: 28,
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_admin_corpo',
				},
			},
			{
				name: 'Fee x servicio',
				value: 'fee_por_servicio',
				defaultValues: {
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_por_servicio',
				},
			},
			{
				name: 'Optimizacion',
				value: 'optimizacion',
				defaultValues: {
					sale: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'optimizacion',
				},
			},
			{
				name: 'Costo de Medio de pago',
				value: 'cto_medio_de_pago',
				defaultValues: {
					sale: 0,
					providerId: '5y89fc9qecr3zypdp04ebfg04s0qbrbl',
					providerName: 'Bamboo Payments / Siempre Pago',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_medio_de_pago',
				},
			},
			{
				name: 'Cto de transferencia',
				value: 'transferencia',
				defaultValues: {
					sale: 0,
					providerId: '4ilhdgxd55ppizd2f2y077ic3rsjl7au',
					providerName: 'Servicios Financieros S.A.',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'transferencia',
				},
			},
		],
	},
	CO: {
		typeOptions: [
			{
				name: 'Fee administrativo',
				value: 'fee_admin_corpo',
				defaultValues: {
					sale: 28,
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'hyn2wmsjiufraqg4bxwmk9wrgr3nsb91',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo CO',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_admin_corpo',
				},
			},
			{
				name: 'Fee x servicio',
				value: 'fee_por_servicio',
				defaultValues: {
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'hyn2wmsjiufraqg4bxwmk9wrgr3nsb91',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo CO',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_por_servicio',
				},
			},
			{
				name: 'Optimizacion',
				value: 'optimizacion',
				defaultValues: {
					sale: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'hyn2wmsjiufraqg4bxwmk9wrgr3nsb91',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo CO',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'optimizacion',
				},
			},
			{
				name: 'Costo de Medio de pago',
				value: 'cto_medio_de_pago',
				defaultValues: {
					sale: 0,
					providerId: 'ctg3nuhbf27oaxa31shtnf0vkygcjtl7',
					providerName: 'Wompi',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_medio_de_pago',
				},
			},
			{
				name: 'Cto de transferencia',
				value: 'transferencia',
				defaultValues: {
					sale: 0,
					providerId: '4ilhdgxd55ppizd2f2y077ic3rsjl7au',
					providerName: 'Servicios Financieros S.A.',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'transferencia',
				},
			},
		],
	},
	PY: {
		typeOptions: [
			{
				name: 'Fee administrativo',
				value: 'fee_admin_corpo',
				defaultValues: {
					sale: 28,
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'zdx0qnrmw9n3o1mtevonbsjttn42ceiv',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo PY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_admin_corpo',
				},
			},
			{
				name: 'Fee x servicio',
				value: 'fee_por_servicio',
				defaultValues: {
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'zdx0qnrmw9n3o1mtevonbsjttn42ceiv',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo PY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_por_servicio',
				},
			},
			{
				name: 'Optimizacion',
				value: 'optimizacion',
				defaultValues: {
					sale: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'zdx0qnrmw9n3o1mtevonbsjttn42ceiv',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo PY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'optimizacion',
				},
			},
			{
				name: 'Costo de Medio de pago',
				value: 'cto_medio_de_pago',
				defaultValues: {
					sale: 0,
					providerId: '08x4k7sbnbgsnxqervujhajhvcszmedo',
					providerName: 'Bancard SA',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_medio_de_pago',
				},
			},
			{
				name: 'Cto de transferencia',
				value: 'transferencia',
				defaultValues: {
					sale: 0,
					providerId: '4ilhdgxd55ppizd2f2y077ic3rsjl7au',
					providerName: 'Servicios Financieros S.A.',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'transferencia',
				},
			},
		],
	},
	BO: {
		typeOptions: [
			{
				name: 'Fee administrativo',
				value: 'fee_admin_corpo',
				defaultValues: {
					sale: 28,
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'jej4kkj680z4o0zk0b2efelegdj58xbh',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo BO',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_admin_corpo',
				},
			},
			{
				name: 'Fee x servicio',
				value: 'fee_por_servicio',
				defaultValues: {
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'jej4kkj680z4o0zk0b2efelegdj58xbh',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo BO',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_por_servicio',
				},
			},
			{
				name: 'Optimizacion',
				value: 'optimizacion',
				defaultValues: {
					sale: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'jej4kkj680z4o0zk0b2efelegdj58xbh',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo BO',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'optimizacion',
				},
			},
			{
				name: 'Costo de Medio de pago',
				value: 'cto_medio_de_pago',
				defaultValues: {
					sale: 0,
					providerId: 'i7jzk9w09jh58pwpowgw33zlwz1bxz1b',
					providerName: 'ATC Red Enlace',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_medio_de_pago',
				},
			},
			{
				name: 'Cto de transferencia',
				value: 'transferencia',
				defaultValues: {
					sale: 0,
					providerId: 't7xfv22tam7mh5e9hptkckuqj09931bx',
					providerName: 'Dlocal Go',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'transferencia',
				},
			},
		],
	},
	TN: {
		typeOptions: [
			{
				name: 'Costo operativo vuelos',
				value: 'cto_oper_vuelos',
				defaultValues: {
					sale: 0,
					cost: 13.64,
					markup: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_oper_vuelos',
				},
			},
			{
				name: 'Costo operativo alojamiento',
				value: 'cto_oper_alojamiento',
				defaultValues: {
					sale: 0,
					markup: 0,
					cost: 3.22,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_oper_alojamiento',
				},
			},
			{
				name: 'Costo operativo paquetes',
				value: 'cto_oper_paquetes',
				defaultValues: {
					sale: 0,
					markup: 0,
					cost: 16.86,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_oper_paquetes',
				},
			},
			{
				name: 'Costo operativo corpo',
				value: 'cto_oper_corpo',
				defaultValues: {
					sale: 0,
					markup: 0,
					cost: 18,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_oper_corpo',
				},
			},
			{
				name: 'Fee administrativo',
				value: 'fee_admin_corpo',
				defaultValues: {
					sale: 28,
					cost: 28,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'ecfuvj9eekq9axcbfx7z8ie35sphv2nr',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo UY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_admin_corpo',
				},
			},
			{
				name: 'Fee x servicio',
				value: 'fee_por_servicio',
				defaultValues: {
					cost: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'zdx0qnrmw9n3o1mtevonbsjttn42ceiv',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo PY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'fee_por_servicio',
				},
			},
			{
				name: 'Optimizacion',
				value: 'optimizacion',
				defaultValues: {
					sale: 0,
					// providerId: 'd2befdb1-de1e-57y4-8257-8672a26f27a9',
					providerId: 'zdx0qnrmw9n3o1mtevonbsjttn42ceiv',
					// providerName: 'Proveedor 4',
					providerName: 'Mevuelo PY',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'optimizacion',
				},
			},
			{
				name: 'Costo de Medio de pago',
				value: 'cto_medio_de_pago',
				defaultValues: {
					sale: 0,
					providerId: '08x4k7sbnbgsnxqervujhajhvcszmedo',
					providerName: 'Bancard SA',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'cto_medio_de_pago',
				},
			},
			{
				name: 'Cto de transferencia',
				value: 'transferencia',
				defaultValues: {
					sale: 0,
					providerId: '4ilhdgxd55ppizd2f2y077ic3rsjl7au',
					providerName: 'Servicios Financieros S.A.',
					setAllPax: true,
					serviceUnit: ServiceUnitEnum.Group,
					feeServiceType: 'transferencia',
				},
			},
		],
	},
};
