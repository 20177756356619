import { CookieService, SessionService } from './services/CookieService';
import { ApiError } from './types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthService } from 'features/auth/services/AuthService';
import { AuthState, LoginPayload, LoginResponse, User } from 'features/auth/types';
import { CountryCodeEnum } from '../common/types';
import { findCountryWithAgencyConfigForUser, getApiKey } from './helpers';
import { defaultCountryWithAgencyConfig } from './constants';

const initialState: AuthState = {
	user: null,
	status: 'initializing',
	needsPasswordChange: false,
	country: defaultCountryWithAgencyConfig.id,
	apiKey: defaultCountryWithAgencyConfig.apiKey,
};

export const doLogin = createAsyncThunk<LoginResponse, LoginPayload, { rejectValue: ApiError }>(
	'auth/login',
	async (payload, thunkApi) => {
		try {
			const response = await AuthService.postLogin(payload);
			const loginMeVueloPayload = {
				userName: payload.login,
				password: payload.password,
			};

			try {
				const meVueloResponse = await AuthService.postLoginMevuelo(loginMeVueloPayload);
				CookieService.setMeVueloToken(meVueloResponse.data.accessToken);
			} catch (e) {
				console.error('No Auth Mevuelo Api');
			}

			CookieService.setToken(response.data.token, response.data.hours);
			return response.data;
		} catch (error) {
			if (error.isAxiosError) {
				return thunkApi.rejectWithValue(error.response.data as ApiError);
			} else {
				return thunkApi.rejectWithValue(error);
			}
		}
	},
);

export const fetchProfile = createAsyncThunk<User>('auth/getProfile', async () => {
	const response = await AuthService.getProfile();
	return response.data;
});

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: () => {
			AuthService.postLogout();
			CookieService.clearAgencies();
			return { ...initialState, status: 'idle' } as AuthState;
		},
		setIdle: (state) => {
			state.status = 'idle';
		},
		setCountry: (state, { payload }: PayloadAction<CountryCodeEnum>) => {
			const apiKey = getApiKey(payload);
			SessionService.setApiKey(apiKey);
			state.country = payload;
			state.apiKey = apiKey;
			setTimeout(() => {
				location.reload();
			}, 500);
		},
		setUpdateCountry: (state, { payload }: PayloadAction<CountryCodeEnum>) => {
			const apiKey = getApiKey(payload);
			SessionService.setApiKey(apiKey);
			state.country = payload;
			state.apiKey = apiKey;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(doLogin.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(doLogin.rejected, (state, { payload }) => {
			state.status = 'failed';
			state.errorMessage = payload?.message;
		});
		builder.addCase(doLogin.fulfilled, (state, { payload }) => {
			state.status = 'succeeded';
			state.user = payload.user;
			CookieService.setAgencies(JSON.stringify(payload.user.agencies));
			state.needsPasswordChange = payload.needChangePassword;
			const existDefault = payload.user.agencies.some((x) => x.agencyId == defaultCountryWithAgencyConfig.agencyId);

			if (!existDefault) {
				const maybeCountryConfig = findCountryWithAgencyConfigForUser(payload.user.agencies[0].agencyId);
				if (maybeCountryConfig) {
					SessionService.setApiKey(maybeCountryConfig.apiKey);
					state.apiKey = maybeCountryConfig.apiKey;
					state.country = maybeCountryConfig.id;
				}
			} else {
				SessionService.setApiKey(defaultCountryWithAgencyConfig.apiKey);
				state.apiKey = defaultCountryWithAgencyConfig.apiKey;
				state.country = defaultCountryWithAgencyConfig.id;
			}
			location.reload();
		});
		builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
			state.status = 'succeeded';
			state.user = payload;
		});
		builder.addCase(fetchProfile.rejected, (state) => {
			state.status = 'idle';
		});
	},
});

export const { reducer, actions } = authSlice;
