import { Button } from '@mui/material';
import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';

interface ExportToExcelButtonProps {
	handleExportToExcel: () => void;
	disabled: boolean;
}

export const ExportToExcelButton = ({ disabled, handleExportToExcel }: ExportToExcelButtonProps): JSX.Element => {
	return (
		<Button
			disabled={disabled}
			variant="contained"
			size="large"
			startIcon={<GetAppIcon />}
			title={'Exportar a excel'}
			onClick={handleExportToExcel}
			sx={{ marginRight: '10px' }}
		/>
	);
};
