import React from 'react';

import { IValidatingCarrier } from 'features/transactions/types';
import { CountryCodeEnum, INetsuiteSyncStatusDTO } from '../common/types';
import { ICustomerCorpoDTO, ICustomerLeisureDTO } from 'features/customers/types';

import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AccomodationProviderItem } from '../common/components/AutocompleteAccomodationProviders';
import { AttachMoney } from '@mui/icons-material';

export interface ISalesOrderList {
	page: number;
	size: number;
	total: number;
	sort?: string;
	elements: ISaleOrderListItem[];
}

export enum PaymentReceivedRelationTypeEnum {
	MVOrderTransaction = 'MVOrderTransaction',
	VNOrderTransaction = 'VNOrderTransaction',
	CashPayment = 'CashPayment',
	WireTransferPayment = 'WireTransferPayment',
	MVHotelReserve = 'MVHotelReserve',
	VNFlightReserve = 'VNFlightReserve',
	VNHotelReserveMV = 'VNHotelReserveMV',
}

export enum PaymentReceivedPlatformTypeEnum {
	PaymentSimulator = 'PaymentSimulator',
	SiemprePago = 'SiemprePago',
	Bancard = 'Bancard',
	ItauPoints = 'ItauPoints',
	ATCRedEnlace = 'ATCRedEnlace',
	Wompi = 'Wompi',
	Cash = 'Cash',
	WireTransfer = 'WireTransfer',
}
export interface ITransaction {
	orderId?: string;
	transactionId: string;
	authorizationId?: number;
	transactionAmount: number;
	transactionCurrency: string;
	amountInMainCurrency: number;
	relationType: PaymentReceivedRelationTypeEnum; // Puedes ajustar el tipo según sea necesario
	platformType: PaymentReceivedPlatformTypeEnum; // Puedes ajustar el tipo según sea necesario
	createdAt: string;
	observations?: string;
}
export interface ISaleOrderDetail {
	id: string;
	segmentType: SegmentEnum;
	dealId: number;
	quotationId: number;
	clientReference?: string;
	automaticSyncWithNetsuite: boolean;
	created: string;
	createdBy: string;
	updated: string;
	updatedBy: string;
	currency: string;
	travelDepartureDate: string;
	travelReturnDate: string;
	saleAmount: number;
	totalCost: number;
	grossProfit: number;
	grossProfitPercentage: number;
	chargedAmount: number;
	pendingPayAmount: number;
	destinations: string;
	orderNumber: number;
	channel: IChannel;
	//TODO : en el proximo PR cuando se implemente en la Sale Order se agregan
	passengers: IPassenger[];
	services: IServices[];
	customer: ICustomerLeisureDTO | ICustomerCorpoDTO;
	syncStatus: INetsuiteSyncStatusDTO;
}
export interface IPatchSaleOrder {
	channelId?: number;
	segmentType?: string;
	clientReference?: string;
	automaticSyncWithNetsuite?: boolean;
}

export interface ISaleOrderListItem {
	id: string;
	dealId: string;
	orderNumber: number;
	quotationId?: string;
	clientReference?: string;
	created: string;
	currency: string;
	travelDepartureDate?: string;
	travelReturnDate?: string;
	saleAmount?: number;
	totalCost?: number;
	grossProfit?: number;
	chargedAmount?: number;
	pendingPayAmount?: number;
	destinations?: string[];
	servicesType?: TypeServiceEnum;
	mainPassengerCompleteName?: string;
	customersCompleteName?: string[];
	market: CountryCodeEnum;
	updated?: string;
	createdBy: string;
	servicesTypes?: string[];
	automaticSyncWithNetsuite?: boolean;
	lastSuccessSyncAt?: string;
	segmentType?: string;
	channel?: { id: number; name: string };
}
export interface ISalesOrderCustomer {
	page: number;
	size: number;
	total: number;
	sort?: string;
	elements: ISaleOrderCustomerListItem[];
}
export interface ISaleOrderCustomerListItem {
	id: string;
	name: string;
	lastName: string;
	email: string;
	phone: string;
	taxIdentificationNumber: string;
	street: string;
	cityName: string;
	stateName: string;
	postaleCode: string;
	country: ICodeName;
}

export interface IPassengerForm {
	id: string;
	keyId: string;
	name: string;
	lastName: string;
	state?: string;
	province: string;
	postalCode?: string;
	address?: string;
	municipality: string;
	countryId: string;
	taxPayerTypeId?: string;
	taxRegimeId?: string;
	taxResidenceTypeId?: string;
	fiscalResponsibilityId?: string;
	isPhysicalPerson: boolean;
	invoiceable: boolean;
	birthdate: string;
	mainPassenger: boolean;
	documents: {
		keyId: string;
		documentType: PassengerDocumentEnum;
		country: string;
		documentNumber: string;
		expirationDate: string;
		image?: string | null;
	}[];
	ageAtEndTravel?: number;
	phone?: string;
	email?: string;
	documentType?: number;
	documentNumber?: string;
	frequentFlyerNumbers: IFrequentFlyerNumber[];
	documentVerificationNumber?: string;
	syncStatus: INetsuiteSyncStatusDTO | null;
	seatPreference?: string;
}

export interface IDocumentForm {
	keyId: string;
	documentType: PassengerDocumentEnum;
	country: string;
	documentNumber: string;
	expirationDate: string;
	image?: string | null;
}

export interface IFrequentFlyerNumber {
	airlineCode: string;
	frequentFlyerNumber: string;
}

export interface IPassenger {
	id: string;
	keyId: string;
	name: string;
	lastName: string;
	state?: string;
	province?: string;
	municipality?: string;
	countryId: number;
	birthdate?: string;
	mainPassenger: boolean;
	documents: IDocuments[];
	frequentFlyerNumbers: IFrequentFlyerNumber[];
	ageAtEndTravel?: number;
	taxPayerTypeId?: number;
	taxRegimeId?: number;
	taxResidenceTypeId?: number;
	fiscalResponsibilityId?: number;
	isPhysicalPerson?: boolean;
	invoiceable?: boolean;
	phone?: string;
	email?: string;
	documentType?: number;
	documentNumber?: string;
	documentVerificationNumber?: string;
	syncStatus?: INetsuiteSyncStatusDTO;
	seatPreference?: string;
}
export interface ICodeName {
	code: string;
	name: string;
}
export interface IDocuments {
	keyId: string;
	documentType: number;
	country: ICodeName;
	documentNumber: string;
	expirationDate: string;
	image?: string | null;
}
export enum PassengerDocumentEnum {
	CEDULA = 'Cedula',
	DRIVERLICENSE = 'DriverLicense',
	PASSPORT = 'Passport',
}
export interface IPassengerDocumentType {
	id: PassengerDocumentEnum;
	name: string;
}
export const passengerDocumentType: IPassengerDocumentType[] = [
	{
		id: PassengerDocumentEnum.CEDULA,
		name: 'Cedula',
	},
	{
		id: PassengerDocumentEnum.DRIVERLICENSE,
		name: 'DriverLicense',
	},
	{
		id: PassengerDocumentEnum.PASSPORT,
		name: 'Passport',
	},
];
export interface IPassengerAutocomplete {
	passengerId: string;
	name: string;
	email: string;
	lastName: string;
	birthdate: string;
	documents: IDocumentsAutocomplete[];
	isPhysicalPerson: boolean;
}
export interface IDocumentsAutocomplete {
	documentType: PassengerDocumentEnum;
	documentNumber: string;
}
export interface ISaleOrderCreateForm {
	dealId: number;
	quotationId?: number;
	segmentType: SegmentEnum;
	channelId: number;
	clientReference?: string;
}
export interface ICurrency {
	code: string;
	name: string;
}
export interface ICurrencyByMarket {
	market: CountryCodeEnum;
	currencies: ICurrency[];
}
export const changesLog = [
	{
		dateTime: '2023-04-07 13:22',
		upDatedBy: 'Juan',
		salesAmount: 1111,
		salesAmountVariation: 0,
		totalCost: 950,
		totalCostVariation: 50,
		grossProfit: 150,
		grossProfitVariation: -50,
		description: 'Flight schedule changed',
	},
	{
		dateTime: '2023-16-10 14:10',
		upDatedBy: 'Andres',
		salesAmount: 1111,
		salesAmountVariation: 0,
		totalCost: 950,
		totalCostVariation: 50,
		grossProfit: 150,
		grossProfitVariation: -50,
		description: 'Flight schedule changed',
	},
	{
		dateTime: '2022-21-08 12:30',
		upDatedBy: 'Pablo',
		salesAmount: 1111,
		salesAmountVariation: 0,
		totalCost: 950,
		totalCostVariation: 50,
		grossProfit: 150,
		grossProfitVariation: -50,
		description: 'Flight schedule changed',
	},
	{
		dateTime: '2023-04-11 14:32',
		upDatedBy: 'Rodrigo',
		salesAmount: 1111,
		salesAmountVariation: 0,
		totalCost: 950,
		totalCostVariation: 50,
		grossProfit: 150,
		grossProfitVariation: -50,
		description: 'Flight schedule changed',
	},
];
export const changesLogGeneral = [
	{
		date: '17-03-2023',
		upDatedBy: 'Juan',
		salesAmount: 1111,
		salesAmountVariation: 0,
		totalCost: 950,
		totalCostVariation: 50,
		grossProfit: 150,
		grossProfitVariation: -50,
		description: 'Flight schedule changed',
	},
	{
		date: '10-07-2023',
		upDatedBy: 'Juan',
		salesAmount: 1311,
		salesAmountVariation: 1311,
		totalCost: 1050,
		totalCostVariation: 1050,
		grossProfit: 360,
		grossProfitVariation: 360,
		description: 'start quotation',
	},
];
export const salesOrder = [
	{
		page: 1,
		size: 10,
		total: 5,
		elements: [
			{
				id: '23456',
				created: '14 May 2023',
				dealId: '#23456',
				quotationId: '23456',
				currency: 'USD',
				salesAmount: 1200,
				totalCost: 800,
				grossProfit: 400,
				chargedAmount: 1000,
				pendingPayAmount: 200,
				destinations: ['New York', 'London', 'Miami'],
				travelDepartureDate: '',
				travelReturnDate: '',
				servicesType: [
					'flight',
					'hotel',
					'insurance',
					'circuit',
					'carRental',
					'train',
					'bus',
					'flight add-ons',
					'other',
				],
				mainPassengerCompleteName: 'Juan Perez',
				customer: 'CNDEF',
				market: 'UY',
				updated: '1 Ago 2023',
				createdBy: 'Santiago Verenice',
			},
			{
				id: '23456',
				created: '14 May 2023',
				dealId: '#23456',
				quotationId: '23456',
				currency: 'USD',
				salesAmount: 1200,
				totalCost: 800,
				grossProfit: 400,
				chargedAmount: 1000,
				pendingPayAmount: 200,
				destinations: ['New York'],
				travelDepartureDate: '',
				travelReturnDate: '',
				servicesType: ['flight', 'hotel', 'insurance', 'circuit', 'carRental', 'train', 'bus'],
				mainPassengerCompleteName: 'Juan Perez',
				customer: 'CNDEF',
				market: 'UY',
				updated: '1 Ago 2023',
				createdBy: 'Santiago Verenice',
			},
		],
	},
];

export enum SegmentEnum {
	LEISURE = 'Leisure',
	CORPO = 'Corpo',
	WHOLESALER = 'Wholesaler',
}

export interface ISegmentType {
	id: SegmentEnum;
	name: string;
}

export const segmentTypes: ISegmentType[] = [
	{
		id: SegmentEnum.LEISURE,
		name: 'Leisure',
	},
	{
		id: SegmentEnum.CORPO,
		name: 'Corpo',
	},
	{
		id: SegmentEnum.WHOLESALER,
		name: 'Mayorista',
	},
];

export const getNameServiceType = (servicesType: TypeServiceEnum): string => {
	const nameServiceType = serviceTypes.find((x) => x.id == servicesType)?.name;
	return nameServiceType ? nameServiceType : '';
};
export enum TypeNationalitesEnum {
	Andorra,
	UnitedArabEmirates,
	Afghanistan,
	AntiguaandBarbuda,
	Anguilla,
	Albania,
	Armenia,
	NetherlandsAntilles,
	Angola,
	Antarctica,
	Argentina,
	AmericanSamoa,
	Austria,
	Australia,
	Aruba,
	ÅlandIslands,
	Azerbaijan,
	BosniaandHerzegovina,
	Barbados,
	Bangladesh,
	Belgium,
	BurkinaFaso,
	Bulgaria,
	Bahrain,
	Burundi,
	Benin,
	SaintBarthélemy,
	Bermuda,
	BruneiDarussalam,
	Bolivia,
	SintEustatiusandSaba,
	Brazil,
	Bahamas,
	Bhutan,
	BouvetIsland,
	Botswana,
	Belarus,
	Belize,
	Canada,
	CocosKeelingIslands,
	CongoRepublic,
	African,
	Congo,
	Switzerland,
	CôteDIvoire,
	CookIslands,
	Chile,
	Cameroon,
	China,
	Colombia,
	CostaRica,
	Cuba,
	CapeVerde,
	Curaçao,
	ChristmasIsland,
	Cyprus,
	CzechRepublic,
	Germany,
	Djibouti,
	Denmark,
	Dominica,
	DominicanRepublic,
	Algeria,
	Ecuador,
	Estonia,
	Egypt,
	WesternSahara,
	Eritrea,
	España,
	Ethiopia,
	Finland,
	Fiji,
	FalklandIslandsMalvinas,
	Micronesia,
	FaroeIslands,
	France,
	Gabon,
	UnitedKingdom,
	Grenada,
	Georgia,
	FrenchGuiana,
	Guernsey,
	Ghana,
	Gibraltar,
	Greenland,
	Gambia,
	Guinea,
	Guadeloupe,
	EquatorialGuinea,
	Greece,
	SouthGeorgia,
	Guatemala,
	Guam,
	GuineaBissau,
	Guyana,
	HongKong,
	Honduras,
	Croatia,
	Haiti,
	Hungary,
	Indonesia,
	Ireland,
	Israel,
	IsleofMan,
	India,
	BritishIndian,
	Iraq,
	IranIslamic,
	Iceland,
	Italy,
	Jersey,
	Jamaica,
	Jordan,
	Japan,
	Kenya,
	Kyrgyzstan,
	Cambodia,
	Kiribati,
	Comoros,
	SaintKittsAndNevis,
	KoreaDemocratic,
	KoreaRepublic,
	Kuwait,
	CaymanIslands,
	Kazakhstan,
	LaoPeoples,
	Lebanon,
	SaintLucia,
	Liechtenstein,
	SriLanka,
	Liberia,
	Lesotho,
	Lithuania,
	Luxembourg,
	Latvia,
	Libya,
	Morocco,
	Monaco,
	MoldovaRepublic,
	Montenegro,
	SaintMartin,
	Madagascar,
	MarshallIslands,
	Macedonia,
	Mali,
	Myanmar,
	Mongolia,
	Macao,
	NorthernMarianaIslands,
	Martinique,
	Mauritania,
	Montserrat,
	Malta,
	Mauritius,
	Maldives,
	Malawi,
	Mexico,
	Malaysia,
	Mozambique,
	Namibia,
	NewCaledonia,
	Niger,
	NorfolkIsland,
	Nigeria,
	Nicaragua,
	Netherlands,
	Norway,
	Nepal,
	Nauru,
	Niue,
	NewZealand,
	Oman,
	Panama,
	Peru,
	FrenchPolynesia,
	PapuaNewGuinea,
	Philippines,
	Pakistan,
	Poland,
	Pitcairn,
	PuertoRico,
	Palestine,
	Portugal,
	Palau,
	Paraguay,
	Qatar,
	Réunion,
	Romania,
	Serbia,
	RussianFederation,
	Rwanda,
	SaudiArabia,
	SolomonIslands,
	Seychelles,
	Sudan,
	Sweden,
	Singapore,
	SaintHelena,
	Slovenia,
	Slovakia,
	SierraLeone,
	SanMarino,
	Senegal,
	Somalia,
	Suriname,
	SouthSudan,
	SaoTomeandPrincipe,
	ElSalvador,
	Syrian,
	Swaziland,
	TurksandCaicosIslands,
	Chad,
	FrenchSouthernTerritories,
	Togo,
	Thailand,
	Tajikistan,
	Tokelau,
	TimorLeste,
	Turkmenistan,
	Tunisia,
	Tonga,
	Turkey,
	TrinidadandTobago,
	Tuvalu,
	Taiwan,
	Tanzania,
	Ukraine,
	Uganda,
	UnitedStates,
	Uruguay,
	Uzbekistan,
	HolySeeVaticanCityState,
	SaintVincent,
	Venezuela,
	VirginIslandBritish,
	VirginIslandsUS,
	Vietnam,
	Vanuatu,
	WallisandFutuna,
	Samoa,
	Yemen,
	Mayott,
	SouthAfrica,
	Zambia,
	Zimbabwe,
}

export interface INationalities {
	id: TypeNationalitesEnum;
	name: string;
	flagsCode: string;
}

export const typeNationalites: INationalities[] = [
	{
		id: TypeNationalitesEnum.Andorra,
		name: 'Andorra',
		flagsCode: 'AD',
	},
	{
		id: TypeNationalitesEnum.UnitedArabEmirates,
		name: 'United Arab Emirates',
		flagsCode: 'AE',
	},
	{
		id: TypeNationalitesEnum.Afghanistan,
		name: 'Afghanistan',
		flagsCode: 'AF',
	},
	{
		id: TypeNationalitesEnum.AntiguaandBarbuda,
		name: 'Antigua and Barbuda',
		flagsCode: 'AG',
	},
	{
		id: TypeNationalitesEnum.Anguilla,
		name: 'Anguilla',
		flagsCode: 'AI',
	},
	{
		id: TypeNationalitesEnum.Albania,
		name: 'Albania',
		flagsCode: 'AL',
	},
	{
		id: TypeNationalitesEnum.Armenia,
		name: 'Armenia',
		flagsCode: 'AM',
	},
	{
		id: TypeNationalitesEnum.NetherlandsAntilles,
		name: 'Netherlands Antilles',
		flagsCode: 'AN',
	},
	{
		id: TypeNationalitesEnum.Angola,
		name: 'Angola',
		flagsCode: 'AO',
	},
	{
		id: TypeNationalitesEnum.Antarctica,
		name: 'Antarctica',
		flagsCode: 'AQ',
	},
	{
		id: TypeNationalitesEnum.Argentina,
		name: 'Argentina',
		flagsCode: 'AR',
	},
	{
		id: TypeNationalitesEnum.AmericanSamoa,
		name: 'American Samoa',
		flagsCode: 'AS',
	},
	{
		id: TypeNationalitesEnum.Austria,
		name: 'Austria',
		flagsCode: 'AT',
	},
	{
		id: TypeNationalitesEnum.Australia,
		name: 'Australia',
		flagsCode: 'AU',
	},
	{
		id: TypeNationalitesEnum.Aruba,
		name: 'Aruba',
		flagsCode: 'AW',
	},
	{
		id: TypeNationalitesEnum.ÅlandIslands,
		name: 'Åland Islands',
		flagsCode: 'AX',
	},
	{
		id: TypeNationalitesEnum.Azerbaijan,
		name: 'Azerbaijan',
		flagsCode: 'AZ',
	},
	{
		id: TypeNationalitesEnum.BosniaandHerzegovina,
		name: 'Bosniaand Herzegovina',
		flagsCode: 'BA',
	},
	{
		id: TypeNationalitesEnum.Barbados,
		name: 'Barbados',
		flagsCode: 'BB',
	},
	{
		id: TypeNationalitesEnum.Bangladesh,
		name: 'Bangladesh',
		flagsCode: 'BD',
	},
	{
		id: TypeNationalitesEnum.Belgium,
		name: 'Belgium',
		flagsCode: 'BE',
	},
	{
		id: TypeNationalitesEnum.BurkinaFaso,
		name: 'Burkina Faso',
		flagsCode: 'BF',
	},
	{
		id: TypeNationalitesEnum.Bulgaria,
		name: 'Bulgaria',
		flagsCode: 'BG',
	},
	{
		id: TypeNationalitesEnum.Bahrain,
		name: 'Bahrain',
		flagsCode: 'BH',
	},
	{
		id: TypeNationalitesEnum.Burundi,
		name: 'Burundi',
		flagsCode: 'BI',
	},
	{
		id: TypeNationalitesEnum.Benin,
		name: 'Benin',
		flagsCode: 'BJ',
	},
	{
		id: TypeNationalitesEnum.SaintBarthélemy,
		name: 'Saint Barthélemy',
		flagsCode: 'BL',
	},
	{
		id: TypeNationalitesEnum.Bermuda,
		name: 'Bermuda',
		flagsCode: 'BM',
	},
	{
		id: TypeNationalitesEnum.BruneiDarussalam,
		name: 'Brunei Darussalam',
		flagsCode: 'BN',
	},
	{
		id: TypeNationalitesEnum.Bolivia,
		name: 'Bolivia',
		flagsCode: 'BO',
	},
	{
		id: TypeNationalitesEnum.SintEustatiusandSaba,
		name: 'Bonaire, Sint Eustatius and Saba',
		flagsCode: 'BQ',
	},
	{
		id: TypeNationalitesEnum.Brazil,
		name: 'Brazil',
		flagsCode: 'BR',
	},
	{
		id: TypeNationalitesEnum.Bahamas,
		name: 'Bahamas',
		flagsCode: 'BS',
	},
	{
		id: TypeNationalitesEnum.Bhutan,
		name: 'Bhutan',
		flagsCode: 'BT',
	},
	{
		id: TypeNationalitesEnum.BouvetIsland,
		name: 'Bouvet Island',
		flagsCode: 'BV',
	},
	{
		id: TypeNationalitesEnum.Botswana,
		name: 'Botswana',
		flagsCode: 'BW',
	},
	{
		id: TypeNationalitesEnum.Belarus,
		name: 'Belarus',
		flagsCode: 'BY',
	},
	{
		id: TypeNationalitesEnum.Belize,
		name: 'Belize',
		flagsCode: 'BZ',
	},
	{
		id: TypeNationalitesEnum.Canada,
		name: 'Canada',
		flagsCode: 'CA',
	},
	{
		id: TypeNationalitesEnum.CocosKeelingIslands,
		name: 'Cocos Keeling Islands',
		flagsCode: 'CC',
	},
	{
		id: TypeNationalitesEnum.CongoRepublic,
		name: 'Congo Democratic Republic',
		flagsCode: 'CD',
	},
	{
		id: TypeNationalitesEnum.African,
		name: 'African',
		flagsCode: 'CF',
	},
	{
		id: TypeNationalitesEnum.Congo,
		name: 'Congo',
		flagsCode: 'CG',
	},
	{
		id: TypeNationalitesEnum.Switzerland,
		name: 'Switzerland',
		flagsCode: 'CH',
	},
	{
		id: TypeNationalitesEnum.CôteDIvoire,
		name: 'Côte DIvoire',
		flagsCode: 'CI',
	},
	{
		id: TypeNationalitesEnum.CookIslands,
		name: 'Cook Islands',
		flagsCode: 'CK',
	},
	{
		id: TypeNationalitesEnum.Chile,
		name: 'Chile',
		flagsCode: 'CL',
	},
	{
		id: TypeNationalitesEnum.Cameroon,
		name: 'Cameroon',
		flagsCode: 'CM',
	},
	{
		id: TypeNationalitesEnum.China,
		name: 'China',
		flagsCode: 'CN',
	},
	{
		id: TypeNationalitesEnum.Colombia,
		name: 'Colombia',
		flagsCode: 'CO',
	},
	{
		id: TypeNationalitesEnum.CostaRica,
		name: 'Costa Rica',
		flagsCode: 'CR',
	},
	{
		id: TypeNationalitesEnum.Cuba,
		name: 'Cuba',
		flagsCode: 'CU',
	},
	{
		id: TypeNationalitesEnum.CapeVerde,
		name: 'Cape Verde',
		flagsCode: 'CV',
	},
	{
		id: TypeNationalitesEnum.Curaçao,
		name: 'Curaçao',
		flagsCode: 'CW',
	},
	{
		id: TypeNationalitesEnum.ChristmasIsland,
		name: 'Christmas Island',
		flagsCode: 'CX',
	},
	{
		id: TypeNationalitesEnum.Cyprus,
		name: 'Cyprus',
		flagsCode: 'CY',
	},
	{
		id: TypeNationalitesEnum.CzechRepublic,
		name: 'Czech Republic',
		flagsCode: 'CZ',
	},
	{
		id: TypeNationalitesEnum.Germany,
		name: 'Germany',
		flagsCode: 'DE',
	},
	{
		id: TypeNationalitesEnum.Djibouti,
		name: 'Djibouti',
		flagsCode: 'DJ',
	},
	{
		id: TypeNationalitesEnum.Denmark,
		name: 'Denmark',
		flagsCode: 'DK',
	},
	{
		id: TypeNationalitesEnum.Dominica,
		name: 'Dominica',
		flagsCode: 'DM',
	},
	{
		id: TypeNationalitesEnum.DominicanRepublic,
		name: 'Dominican Republic',
		flagsCode: 'DO',
	},
	{
		id: TypeNationalitesEnum.Algeria,
		name: 'Algeria',
		flagsCode: 'DZ',
	},
	{
		id: TypeNationalitesEnum.Ecuador,
		name: 'Ecuador',
		flagsCode: 'EC',
	},
	{
		id: TypeNationalitesEnum.Estonia,
		name: 'Estonia',
		flagsCode: 'EE',
	},
	{
		id: TypeNationalitesEnum.Egypt,
		name: 'Egypt',
		flagsCode: 'EG',
	},
	{
		id: TypeNationalitesEnum.WesternSahara,
		name: 'Western Sahara',
		flagsCode: 'EH',
	},
	{
		id: TypeNationalitesEnum.Eritrea,
		name: 'Eritrea',
		flagsCode: 'ER',
	},
	{
		id: TypeNationalitesEnum.España,
		name: 'España',
		flagsCode: 'ES',
	},
	{
		id: TypeNationalitesEnum.Ethiopia,
		name: 'Ethiopia',
		flagsCode: 'ET',
	},
	{
		id: TypeNationalitesEnum.Finland,
		name: 'Finlan',
		flagsCode: 'FI',
	},
	{
		id: TypeNationalitesEnum.Fiji,
		name: 'Fiji',
		flagsCode: 'FJ',
	},
	{
		id: TypeNationalitesEnum.FalklandIslandsMalvinas,
		name: 'Falkland Islands (Malvinas)',
		flagsCode: 'FK',
	},
	{
		id: TypeNationalitesEnum.Micronesia,
		name: 'Micronesia',
		flagsCode: 'FM',
	},
	{
		id: TypeNationalitesEnum.FaroeIslands,
		name: 'Faroe Islands',
		flagsCode: 'FO',
	},
	{
		id: TypeNationalitesEnum.France,
		name: 'France',
		flagsCode: 'FR',
	},
	{
		id: TypeNationalitesEnum.Gabon,
		name: 'Gabon',
		flagsCode: 'GA',
	},
	{
		id: TypeNationalitesEnum.UnitedKingdom,
		name: 'United Kingdom',
		flagsCode: 'GB',
	},
	{
		id: TypeNationalitesEnum.Grenada,
		name: 'Grenada',
		flagsCode: 'GD',
	},
	{
		id: TypeNationalitesEnum.Georgia,
		name: 'Georgia',
		flagsCode: 'GE',
	},
	{
		id: TypeNationalitesEnum.FrenchGuiana,
		name: 'French Guiana',
		flagsCode: 'GF',
	},
	{
		id: TypeNationalitesEnum.Guernsey,
		name: 'Guernsey',
		flagsCode: 'GG',
	},
	{
		id: TypeNationalitesEnum.Ghana,
		name: 'Ghana',
		flagsCode: 'GH',
	},
	{
		id: TypeNationalitesEnum.Gibraltar,
		name: 'Gibraltar',
		flagsCode: 'GI',
	},
	{
		id: TypeNationalitesEnum.Greenland,
		name: 'Greenland',
		flagsCode: 'GL',
	},
	{
		id: TypeNationalitesEnum.Gambia,
		name: 'Gambia',
		flagsCode: 'GM',
	},
	{
		id: TypeNationalitesEnum.Guinea,
		name: 'Guinea',
		flagsCode: 'GN',
	},
	{
		id: TypeNationalitesEnum.Guadeloupe,
		name: 'Guadeloupe',
		flagsCode: 'GP',
	},
	{
		id: TypeNationalitesEnum.EquatorialGuinea,
		name: 'Equatorial Guinea',
		flagsCode: 'GQ',
	},
	{
		id: TypeNationalitesEnum.Greece,
		name: 'Greece',
		flagsCode: 'GR',
	},
	{
		id: TypeNationalitesEnum.SouthGeorgia,
		name: 'South Georgia ',
		flagsCode: 'GS',
	},
	{
		id: TypeNationalitesEnum.Guatemala,
		name: 'Guatemala',
		flagsCode: 'GT',
	},
	{
		id: TypeNationalitesEnum.Guam,
		name: 'Guam',
		flagsCode: 'GU',
	},
	{
		id: TypeNationalitesEnum.GuineaBissau,
		name: 'Guinea-Bissau',
		flagsCode: 'GW',
	},
	{
		id: TypeNationalitesEnum.Guyana,
		name: 'Guyana',
		flagsCode: 'GY',
	},
	{
		id: TypeNationalitesEnum.HongKong,
		name: 'Hong Kong',
		flagsCode: 'HK',
	},
	{
		id: TypeNationalitesEnum.Honduras,
		name: 'Honduras',
		flagsCode: 'HN',
	},
	{
		id: TypeNationalitesEnum.Croatia,
		name: 'Croatia',
		flagsCode: 'HR',
	},
	{
		id: TypeNationalitesEnum.Haiti,
		name: 'Haiti',
		flagsCode: 'HT',
	},
	{
		id: TypeNationalitesEnum.Hungary,
		name: 'Hungary',
		flagsCode: 'HU',
	},
	{
		id: TypeNationalitesEnum.Indonesia,
		name: 'Indonesia',
		flagsCode: 'ID',
	},
	{
		id: TypeNationalitesEnum.Ireland,
		name: 'Ireland',
		flagsCode: 'IE',
	},
	{
		id: TypeNationalitesEnum.Israel,
		name: 'Israel',
		flagsCode: 'IL',
	},
	{
		id: TypeNationalitesEnum.IsleofMan,
		name: 'Isle of Man',
		flagsCode: 'IM',
	},
	{
		id: TypeNationalitesEnum.India,
		name: 'India',
		flagsCode: 'IN',
	},
	{
		id: TypeNationalitesEnum.BritishIndian,
		name: 'British Indian',
		flagsCode: 'IO',
	},
	{
		id: TypeNationalitesEnum.Iraq,
		name: 'Iraq',
		flagsCode: 'IQ',
	},
	{
		id: TypeNationalitesEnum.IranIslamic,
		name: 'Iran, Islamic',
		flagsCode: 'IR',
	},
	{
		id: TypeNationalitesEnum.Iceland,
		name: 'Iceland',
		flagsCode: 'IS',
	},
	{
		id: TypeNationalitesEnum.Italy,
		name: 'Italy',
		flagsCode: 'IT',
	},
	{
		id: TypeNationalitesEnum.Jersey,
		name: 'Jersey',
		flagsCode: 'JE',
	},
	{
		id: TypeNationalitesEnum.Jamaica,
		name: 'Jamaica',
		flagsCode: 'JM',
	},
	{
		id: TypeNationalitesEnum.Jordan,
		name: 'Jordan',
		flagsCode: 'JO',
	},
	{
		id: TypeNationalitesEnum.Japan,
		name: 'Japan',
		flagsCode: 'JP',
	},
	{
		id: TypeNationalitesEnum.Kenya,
		name: 'Kenya',
		flagsCode: 'KE',
	},
	{
		id: TypeNationalitesEnum.Kyrgyzstan,
		name: 'Kyrgyzstan',
		flagsCode: 'KG',
	},
	{
		id: TypeNationalitesEnum.Cambodia,
		name: 'Cambodia',
		flagsCode: 'KH',
	},
	{
		id: TypeNationalitesEnum.Kiribati,
		name: 'Kiribati',
		flagsCode: 'KI',
	},
	{
		id: TypeNationalitesEnum.Comoros,
		name: 'Comoros',
		flagsCode: 'KM',
	},
	{
		id: TypeNationalitesEnum.SaintKittsAndNevis,
		name: 'Saint Kitts And Nevis',
		flagsCode: 'KN',
	},
	{
		id: TypeNationalitesEnum.KoreaDemocratic,
		name: 'Korea, Democratic',
		flagsCode: 'KP',
	},
	{
		id: TypeNationalitesEnum.KoreaRepublic,
		name: 'Korea Republic',
		flagsCode: 'KR',
	},
	{
		id: TypeNationalitesEnum.Kuwait,
		name: 'Kuwait',
		flagsCode: 'KW',
	},
	{
		id: TypeNationalitesEnum.CaymanIslands,
		name: 'Cayman Islands',
		flagsCode: 'KY',
	},
	{
		id: TypeNationalitesEnum.Kazakhstan,
		name: 'Kazakhstan',
		flagsCode: 'KZ',
	},
	{
		id: TypeNationalitesEnum.LaoPeoples,
		name: 'Lao Peoples',
		flagsCode: 'LA',
	},
	{
		id: TypeNationalitesEnum.Lebanon,
		name: 'Lebanon',
		flagsCode: 'LB',
	},
	{
		id: TypeNationalitesEnum.SaintLucia,
		name: 'Saint Lucia',
		flagsCode: 'LC',
	},
	{
		id: TypeNationalitesEnum.Liechtenstein,
		name: 'Liechtenstein',
		flagsCode: 'LI',
	},
	{
		id: TypeNationalitesEnum.SriLanka,
		name: 'Sri Lanka',
		flagsCode: 'LK',
	},
	{
		id: TypeNationalitesEnum.Liberia,
		name: 'Liberia',
		flagsCode: 'LR',
	},
	{
		id: TypeNationalitesEnum.Lesotho,
		name: 'Lesotho',
		flagsCode: 'LS',
	},
	{
		id: TypeNationalitesEnum.Lithuania,
		name: 'Lithuania',
		flagsCode: 'LT',
	},
	{
		id: TypeNationalitesEnum.Luxembourg,
		name: 'Luxembourg',
		flagsCode: 'LU',
	},
	{
		id: TypeNationalitesEnum.Latvia,
		name: 'Latvia',
		flagsCode: 'LV',
	},
	{
		id: TypeNationalitesEnum.Libya,
		name: 'Libya',
		flagsCode: 'LY',
	},
	{
		id: TypeNationalitesEnum.Morocco,
		name: 'Morocco',
		flagsCode: 'MA',
	},
	{
		id: TypeNationalitesEnum.Monaco,
		name: 'Monaco',
		flagsCode: 'MC',
	},
	{
		id: TypeNationalitesEnum.MoldovaRepublic,
		name: 'Moldova Republic',
		flagsCode: 'MD',
	},
	{
		id: TypeNationalitesEnum.Montenegro,
		name: 'Montenegro',
		flagsCode: 'ME',
	},
	{
		id: TypeNationalitesEnum.SaintMartin,
		name: 'Saint Martin',
		flagsCode: 'MF',
	},
	{
		id: TypeNationalitesEnum.Madagascar,
		name: 'Madagascar',
		flagsCode: 'MG',
	},
	{
		id: TypeNationalitesEnum.MarshallIslands,
		name: 'Marshall Islands',
		flagsCode: 'MH',
	},
	{
		id: TypeNationalitesEnum.Macedonia,
		name: 'Macedonia',
		flagsCode: 'MK',
	},
	{
		id: TypeNationalitesEnum.Mali,
		name: 'Mali',
		flagsCode: 'ML',
	},
	{
		id: TypeNationalitesEnum.Myanmar,
		name: 'Myanmar',
		flagsCode: 'MM',
	},
	{
		id: TypeNationalitesEnum.Mongolia,
		name: 'Mongolia',
		flagsCode: 'MN',
	},
	{
		id: TypeNationalitesEnum.Macao,
		name: 'Macao',
		flagsCode: 'MO',
	},
	{
		id: TypeNationalitesEnum.NorthernMarianaIslands,
		name: 'Northern Mariana Islands',
		flagsCode: 'MP',
	},
	{
		id: TypeNationalitesEnum.Martinique,
		name: 'Martinique',
		flagsCode: 'MQ',
	},
	{
		id: TypeNationalitesEnum.Mauritania,
		name: 'Mauritania',
		flagsCode: 'MR',
	},
	{
		id: TypeNationalitesEnum.Montserrat,
		name: 'Montserrat',
		flagsCode: 'MS',
	},
	{
		id: TypeNationalitesEnum.Malta,
		name: 'Malta',
		flagsCode: 'MT',
	},
	{
		id: TypeNationalitesEnum.Mauritius,
		name: 'Mauritius',
		flagsCode: 'MU',
	},
	{
		id: TypeNationalitesEnum.Maldives,
		name: 'Maldives',
		flagsCode: 'MV',
	},
	{
		id: TypeNationalitesEnum.Malawi,
		name: 'Malawi',
		flagsCode: 'MW',
	},
	{
		id: TypeNationalitesEnum.Mexico,
		name: 'Mexico',
		flagsCode: 'MX',
	},
	{
		id: TypeNationalitesEnum.Malaysia,
		name: 'Malaysia',
		flagsCode: 'MY',
	},
	{
		id: TypeNationalitesEnum.Mozambique,
		name: 'Mozambique',
		flagsCode: 'MZ',
	},
	{
		id: TypeNationalitesEnum.Namibia,
		name: 'Namibia',
		flagsCode: 'NA',
	},
	{
		id: TypeNationalitesEnum.NewCaledonia,
		name: 'New Caledonia',
		flagsCode: 'NC',
	},
	{
		id: TypeNationalitesEnum.Niger,
		name: 'Niger',
		flagsCode: 'NE',
	},
	{
		id: TypeNationalitesEnum.NorfolkIsland,
		name: 'Norfolk Island',
		flagsCode: 'NF',
	},
	{
		id: TypeNationalitesEnum.Nigeria,
		name: 'Nigeria',
		flagsCode: 'NG',
	},
	{
		id: TypeNationalitesEnum.Nicaragua,
		name: 'Nicaragua',
		flagsCode: 'NI',
	},
	{
		id: TypeNationalitesEnum.NewZealand,
		name: 'New Zealand',
		flagsCode: 'NZ',
	},
	{
		id: TypeNationalitesEnum.Oman,
		name: 'Oman',
		flagsCode: 'OM',
	},
	{
		id: TypeNationalitesEnum.Panama,
		name: 'Panama',
		flagsCode: 'PA',
	},
	{
		id: TypeNationalitesEnum.Peru,
		name: 'Peru',
		flagsCode: 'PE',
	},
	{
		id: TypeNationalitesEnum.FrenchPolynesia,
		name: 'French Polynesia',
		flagsCode: 'PF',
	},
	{
		id: TypeNationalitesEnum.PapuaNewGuinea,
		name: 'Papua New Guinea',
		flagsCode: 'PG',
	},
	{
		id: TypeNationalitesEnum.Philippines,
		name: 'Philippines',
		flagsCode: 'PH',
	},
	{
		id: TypeNationalitesEnum.Pakistan,
		name: 'Pakistan',
		flagsCode: 'PK',
	},
	{
		id: TypeNationalitesEnum.Poland,
		name: 'Poland',
		flagsCode: 'PL',
	},
	{
		id: TypeNationalitesEnum.Pitcairn,
		name: 'Pitcairn',
		flagsCode: 'PN',
	},
	{
		id: TypeNationalitesEnum.PuertoRico,
		name: 'Puerto Rico',
		flagsCode: 'PR',
	},
	{
		id: TypeNationalitesEnum.Palestine,
		name: 'Palestine',
		flagsCode: 'PS',
	},
	{
		id: TypeNationalitesEnum.Portugal,
		name: 'Portugal',
		flagsCode: 'PT',
	},
	{
		id: TypeNationalitesEnum.Palau,
		name: 'Palau',
		flagsCode: 'PW',
	},
	{
		id: TypeNationalitesEnum.Paraguay,
		name: 'Paraguay',
		flagsCode: 'PY',
	},
	{
		id: TypeNationalitesEnum.Qatar,
		name: 'Qatar',
		flagsCode: 'QA',
	},
	{
		id: TypeNationalitesEnum.Réunion,
		name: 'Réunion',
		flagsCode: 'RE',
	},
	{
		id: TypeNationalitesEnum.Romania,
		name: 'Romania',
		flagsCode: 'RO',
	},
	{
		id: TypeNationalitesEnum.Serbia,
		name: 'Serbia',
		flagsCode: 'RS',
	},
	{
		id: TypeNationalitesEnum.RussianFederation,
		name: 'Russian Federation',
		flagsCode: 'RU',
	},
	{
		id: TypeNationalitesEnum.Rwanda,
		name: 'Rwanda',
		flagsCode: 'RW',
	},
	{
		id: TypeNationalitesEnum.SaudiArabia,
		name: 'Saudi Arabia',
		flagsCode: 'SA',
	},
	{
		id: TypeNationalitesEnum.SolomonIslands,
		name: 'Solomon Islands',
		flagsCode: 'SB',
	},
	{
		id: TypeNationalitesEnum.Seychelles,
		name: 'Seychelles',
		flagsCode: 'SC',
	},
	{
		id: TypeNationalitesEnum.Sudan,
		name: 'Sudan',
		flagsCode: 'SD',
	},
	{
		id: TypeNationalitesEnum.Sweden,
		name: 'Sweden',
		flagsCode: 'SE',
	},
	{
		id: TypeNationalitesEnum.Singapore,
		name: 'Singapore',
		flagsCode: 'SG',
	},
	{
		id: TypeNationalitesEnum.SaintHelena,
		name: 'Saint Helena',
		flagsCode: 'SH',
	},
	{
		id: TypeNationalitesEnum.Slovenia,
		name: 'Slovenia',
		flagsCode: 'SI',
	},
	{
		id: TypeNationalitesEnum.Slovakia,
		name: 'lovakia',
		flagsCode: 'SK',
	},
	{
		id: TypeNationalitesEnum.SierraLeone,
		name: 'ierra Leone',
		flagsCode: 'SL',
	},
	{
		id: TypeNationalitesEnum.SanMarino,
		name: 'San Marino',
		flagsCode: 'SM',
	},
	{
		id: TypeNationalitesEnum.Senegal,
		name: 'Senegal',
		flagsCode: 'SN',
	},
	{
		id: TypeNationalitesEnum.Somalia,
		name: 'Somalia',
		flagsCode: 'SO',
	},
	{
		id: TypeNationalitesEnum.Suriname,
		name: 'Suriname',
		flagsCode: 'SR',
	},
	{
		id: TypeNationalitesEnum.SouthSudan,
		name: 'South Sudan',
		flagsCode: 'SS',
	},
	{
		id: TypeNationalitesEnum.SaoTomeandPrincipe,
		name: 'Sao Tome and Principe',
		flagsCode: 'ST',
	},
	{
		id: TypeNationalitesEnum.ElSalvador,
		name: 'El Salvador',
		flagsCode: 'SV',
	},
	{
		id: TypeNationalitesEnum.Syrian,
		name: 'Syrian Arab Republic',
		flagsCode: 'SY',
	},
	{
		id: TypeNationalitesEnum.Swaziland,
		name: 'Swaziland',
		flagsCode: 'SZ',
	},
	{
		id: TypeNationalitesEnum.TurksandCaicosIslands,
		name: 'Turks and Caicos Islands',
		flagsCode: 'TC',
	},
	{
		id: TypeNationalitesEnum.Chad,
		name: 'Chad',
		flagsCode: 'TD',
	},
	{
		id: TypeNationalitesEnum.FrenchSouthernTerritories,
		name: 'French Southern Territories',
		flagsCode: 'TF',
	},
	{
		id: TypeNationalitesEnum.Togo,
		name: 'Togo',
		flagsCode: 'TG',
	},
	{
		id: TypeNationalitesEnum.Thailand,
		name: 'Thailand',
		flagsCode: 'TH',
	},
	{
		id: TypeNationalitesEnum.Tajikistan,
		name: 'Tajikistan',
		flagsCode: 'TJ',
	},
	{
		id: TypeNationalitesEnum.Tokelau,
		name: 'Tokelau',
		flagsCode: 'TK',
	},
	{
		id: TypeNationalitesEnum.TimorLeste,
		name: 'Timor-Leste',
		flagsCode: 'TL',
	},
	{
		id: TypeNationalitesEnum.Turkmenistan,
		name: 'Turkmenistan',
		flagsCode: 'TM',
	},
	{
		id: TypeNationalitesEnum.Tunisia,
		name: 'Tunisia',
		flagsCode: 'TN',
	},
	{
		id: TypeNationalitesEnum.Tonga,
		name: 'Tonga',
		flagsCode: 'TO',
	},
	{
		id: TypeNationalitesEnum.Turkey,
		name: 'Turkey',
		flagsCode: 'TR',
	},
	{
		id: TypeNationalitesEnum.TrinidadandTobago,
		name: 'Trinidad and Tobago',
		flagsCode: 'TT',
	},
	{
		id: TypeNationalitesEnum.Tuvalu,
		name: 'Tuvalu',
		flagsCode: 'TV',
	},
	{
		id: TypeNationalitesEnum.Taiwan,
		name: 'Taiwan',
		flagsCode: 'TW',
	},
	{
		id: TypeNationalitesEnum.Tanzania,
		name: 'Tanzania',
		flagsCode: 'TZ',
	},
	{
		id: TypeNationalitesEnum.Ukraine,
		name: 'Ukraine',
		flagsCode: 'UA',
	},
	{
		id: TypeNationalitesEnum.Uganda,
		name: 'Uganda',
		flagsCode: 'UG',
	},
	{
		id: TypeNationalitesEnum.UnitedStates,
		name: 'United States',
		flagsCode: 'US',
	},
	{
		id: TypeNationalitesEnum.Uruguay,
		name: 'Uruguay',
		flagsCode: 'UY',
	},
	{
		id: TypeNationalitesEnum.Uzbekistan,
		name: 'Uzbekistan',
		flagsCode: 'UZ',
	},
	{
		id: TypeNationalitesEnum.HolySeeVaticanCityState,
		name: 'Holy See (Vatican City State)',
		flagsCode: 'VA',
	},
	{
		id: TypeNationalitesEnum.SaintVincent,
		name: 'Saint Vincent ',
		flagsCode: 'VC',
	},
	{
		id: TypeNationalitesEnum.Venezuela,
		name: 'Venezuela',
		flagsCode: 'VE',
	},
	{
		id: TypeNationalitesEnum.VirginIslandBritish,
		name: 'Virgin Islands, British',
		flagsCode: 'VG',
	},
	{
		id: TypeNationalitesEnum.VirginIslandsUS,
		name: 'Virgin Islands, U.S',
		flagsCode: 'VI',
	},
	{
		id: TypeNationalitesEnum.Vietnam,
		name: 'Vietnam',
		flagsCode: 'VN',
	},
	{
		id: TypeNationalitesEnum.Vanuatu,
		name: 'Vanuatu',
		flagsCode: 'VU',
	},
	{
		id: TypeNationalitesEnum.WallisandFutuna,
		name: 'Wallis and Futuna',
		flagsCode: 'WF',
	},
	{
		id: TypeNationalitesEnum.Samoa,
		name: 'Samoa',
		flagsCode: 'WS',
	},
	{
		id: TypeNationalitesEnum.Yemen,
		name: 'Yemen',
		flagsCode: 'YE',
	},
	{
		id: TypeNationalitesEnum.Mayott,
		name: 'Mayott',
		flagsCode: 'YT',
	},
	{
		id: TypeNationalitesEnum.SouthAfrica,
		name: 'South Africa',
		flagsCode: 'ZA',
	},
	{
		id: TypeNationalitesEnum.Zambia,
		name: 'Zambia',
		flagsCode: 'ZM',
	},
	{
		id: TypeNationalitesEnum.Zimbabwe,
		name: 'Zimbabwe',
		flagsCode: 'ZW',
	},
];

export enum TypePersonEnum {
	NATURAL = 1,
	LEGAL,
}

export interface IPersonType {
	id: TypePersonEnum;
	name: string;
}

export const typePerson: IPersonType[] = [
	{
		id: TypePersonEnum.LEGAL,
		name: 'LEGAL',
	},
	{
		id: TypePersonEnum.NATURAL,
		name: 'NATURAL',
	},
];

export enum TypeCustomerPaymentEnum {
	creditCard,
	cash,
	bank,
}
export interface ICustomerPaymentType {
	id: TypeCustomerPaymentEnum;
	name: string;
}
export const typePayment: ICustomerPaymentType[] = [
	{
		id: TypeCustomerPaymentEnum.creditCard,
		name: 'Tarjeta de credito',
	},
	{
		id: TypeCustomerPaymentEnum.cash,
		name: 'Efectivo',
	},
	{
		id: TypeCustomerPaymentEnum.bank,
		name: 'Banco',
	},
];

export interface ICustomerPayments {
	customerPayments: ICustomerPayment[];
}

export interface ICustomerPayment {
	paymentType: string;
}

export interface ICreditCardForm {
	cardHolderName: string;
	cardNumber: string;
	expirationDate: string;
	amount: number;
	transactionId: string;
	dateTime: string;
}

export interface ICashForm {
	amount: number;
	transactionId: string;
	dateTime: string;
}

export interface IBankForm {
	bankName: string;
	bankAccuntNumber: string;
	amount: number;
	transactionId: string;
	dateTime: string;
}

export interface ICustomer {
	id: string;
	person: string;
	mainPassengerId?: string;
	name: string;
	nationality: string;
	documents: IDocuments[];
}

export interface IProvider {
	id: string;
	fantasyName: string;
	businessName: string;
	country: ICountry;
	stateName: string;
	cityName: string;
	street: string;
	postalCode: string;
	rut: string;
	phone: string;
	email: string;
	enable: boolean;
}

export interface IAccommodationService extends IService {
	keyId: string;
	reference: string;
	tariffProvider?: IProvider | string;
	unit: ServiceUnitEnum;
	cost?: string;
	saleAmount?: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	serviceProvider?: AccomodationProviderItem;
	sameTariffServiceProvider: boolean;
	destination: ICodeName;
	startDate: string;
	endDate: string;
	limitDate: string;
	rooms: IRooms[];
	currencyRate?: number;
	totalSaleAmountInLocalCurrency?: number;
}

export interface IRooms {
	keyId: string;
	passengerIds: string[];
	tariffType: AccommodationFeeTypeEnum[];
	room: string;
	board: AccommodationRoomBoardEnum[];
	cost?: string;
	saleAmount?: string;
	markupPercentage?: string;
}

export enum AccommodationFeeTypeEnum {
	Refundable = 'Refundable',
	NonRefundable = 'NonRefundable',
}

export interface IAccommodationFeeType {
	id: AccommodationFeeTypeEnum | '';
	name: string;
}

export const accommodationFeeType: IAccommodationFeeType[] = [
	{
		id: '',
		name: 'No Aplica',
	},
	{
		id: AccommodationFeeTypeEnum.Refundable,
		name: 'Reembolsable',
	},
	{
		id: AccommodationFeeTypeEnum.NonRefundable,
		name: 'No reembolsable',
	},
];

export enum AccommodationRoomBoardEnum {
	Breakfast = 'Breakfast',
	OnlyRoom = 'OnlyRoom',
	HalfBoard = 'HalfBoard',
	FullBoard = 'FullBoard',
	AllInclusive = 'AllInclusive',
}

export interface IAccommodationRoomBoard {
	id: AccommodationRoomBoardEnum;
	name: string;
}

export const accommodationRoomBoard: IAccommodationRoomBoard[] = [
	{
		id: AccommodationRoomBoardEnum.OnlyRoom,
		name: 'Solo habitacion',
	},
	{
		id: AccommodationRoomBoardEnum.Breakfast,
		name: 'Desayuno Incluido',
	},
	{
		id: AccommodationRoomBoardEnum.HalfBoard,
		name: 'Media pensión',
	},
	{
		id: AccommodationRoomBoardEnum.FullBoard,
		name: 'Pensión completa',
	},
	{
		id: AccommodationRoomBoardEnum.AllInclusive,
		name: 'All inclusive',
	},
];

export interface IProviderAutocomplete {
	id: number;
	fantasyName: string;
	businessName: string;
	country: ICountry;
	stateName: string;
	cityName: string;
	street?: string;
	postalCode?: string;
	rut?: string;
	phone?: string;
	email?: string;
	enable: boolean;
}
export interface ICountry {
	code: string;
	name: string;
}

export interface ITicket {
	passengerId: string;
	ticket: string;
}

export interface IFlightService {
	keyId: string;
	reference: string;
	tariffProvider?: string | IProvider;
	unit: ServiceUnitEnum;
	cost?: string;
	markupPercentage: string;
	saleAmount?: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	passengersTickets?: { [key: string]: string }[] | ITicket[];
	gds?: GDSEnum[] | NotApplicable;
	vc: IValidatingCarrier[];
	oidIssueId?: number | string;
	limitDate: string;
	withTst?: string;
	tst: string;
	tqt: string;
	baggageType?: BaggageEnum;
	carryOn?: boolean;
	flightLegs: IFlightServiceLeg[];
	FXL: boolean;
	OPEROK: boolean;
	currencyRate?: number;
	totalSaleAmountInLocalCurrency?: number;
}

export interface IFlightServiceLeg {
	keyId: string;
	flightNumber: string;
	airline: IFlightServiceLegAirline;
	departure: IFlightServiceLegEvent;
	arrival: IFlightServiceLegEvent;
}

export interface IFlightServiceLegEvent {
	airport: IFlightLegAirport;
	date: string;
	hour: string;
}

export interface IFlightLegAirport {
	code: string;
	name: string;
	cityName: string;
}

export interface IFlightServiceLegAirline {
	image: string;
	code: string;
	name: string;
}
export enum BaggageEnum {
	OneWay1x23KG = 'OneWay1x23KG',
	OneWay2x23KG = 'OneWay2x23KG',
	OneWay1x32KG = 'OneWay1x32KG',
	OneWay2x32KG = 'OneWay2x32KG',

	OnlyReturn1x23KG = 'OnlyReturn1x23KG',
	OnlyReturn2x23KG = 'OnlyReturn2x23KG',
	OnlyReturn1x32KG = 'OnlyReturn1x32KG',
	OnlyReturn2x32KG = 'OnlyReturn2x32KG',

	RoundTrip1x23KG = 'RoundTrip1x23KG',
	RoundTrip2x23KG = 'RoundTrip2x23KG',
	RoundTrip1x32KG = 'RoundTrip1x32KG',
	RoundTrip2x32KG = 'RoundTrip2x32KG',

	None = 'None',
}

export enum GDSEnum {
	Amadeus = 'Amadeus',
	Sabre = 'Sabre',
	NDS = 'NDS',
	NDX = 'NDX',
	NDC = 'NDC',
}

export interface IGDSOption {
	id: GDSEnum | 'n/a';
	name: string;
}

export interface ISelectOption {
	id: string;
	name: string;
}

export enum ServiceUnitEnum {
	Person = 'Person',
	Group = 'Group',
	PerRoom = 'PerRoom',
	PerCabin = 'PerCabin',
	Vehicle = 'Vehicle',
}

export interface IUnitType {
	id: ServiceUnitEnum;
	name: string;
}

export const typeUnit: IUnitType[] = [
	{
		id: ServiceUnitEnum.Person,
		name: 'Persona',
	},
	{
		id: ServiceUnitEnum.Group,
		name: 'Grupo',
	},
	{
		id: ServiceUnitEnum.PerRoom,
		name: 'Habitacion',
	},
	{
		id: ServiceUnitEnum.PerCabin,
		name: 'Camarote',
	},
	{
		id: ServiceUnitEnum.Vehicle,
		name: 'Vehiculo',
	},
];

export enum FlightAddOnTypeEnum {
	Luggage = 'Luggage',
	PassengerAssistance = 'PassengerAssistance',
	Pet = 'Pet',
	Seat = 'Seat',
	SpecialFood = 'SpecialFood',
}

export interface IFlightAddOnType {
	id: FlightAddOnTypeEnum;
	name: string;
}

export const flightAddOnType: IFlightAddOnType[] = [
	{
		id: FlightAddOnTypeEnum.Luggage,
		name: 'Equipaje',
	},
	{
		id: FlightAddOnTypeEnum.PassengerAssistance,
		name: 'Asistencia al pasajero',
	},
	{
		id: FlightAddOnTypeEnum.Pet,
		name: 'Mascotas',
	},
	{
		id: FlightAddOnTypeEnum.Seat,
		name: 'Asientos',
	},
	{
		id: FlightAddOnTypeEnum.SpecialFood,
		name: 'Comida especial',
	},
];

export interface IServices {
	serviceType: TypeServiceEnum;
	keyId: string;
	enable?: boolean;
	services: (
		| IInsurance
		| ICircuitService
		| ICarRental
		| ITrainService
		| IActivityService
		| IFlightAddOm
		| IThirdPartyPackage
		| IFerry
		| IOtherService
		| IAccommodationService
	)[];
}

export interface IService {
	serviceType: TypeServiceEnum;
	enable?: boolean;
	keyId: string;
	serviceName: string;
}

export type NotApplicable = 'n/a';

export enum TypeServiceEnum {
	Flight = 'Flight',
	Accommodation = 'Accommodation',
	Transfer = 'Transfer',
	Insurance = 'Insurance',
	Circuit = 'Circuit',
	Train = 'Train',
	CarRental = 'CarRental',
	Bus = 'Bus',
	Activity = 'Activity',
	FlightAddOn = 'FlightAddOn',
	ThirdPartyPackage = 'ThirdPartyPackage',
	Ferry = 'Ferry',
	Cruise = 'Cruise',
	Other = 'Other',
	Fee = 'Fee',
}

export interface IServiceType {
	id: TypeServiceEnum;
	name: string;
	icon: JSX.Element;
}

export const serviceTypes: IServiceType[] = [
	{
		id: TypeServiceEnum.Flight,
		name: 'Vuelos',
		icon: <FlightIcon />,
	},
	{
		id: TypeServiceEnum.Accommodation,
		name: 'Alojamiento',
		icon: <HotelIcon />,
	},

	{
		id: TypeServiceEnum.Transfer,
		name: 'Traslados',
		icon: <AirportShuttleIcon />,
	},
	// TODO: keep removing comments and adding services
	// {
	// 	id: TypeServiceEnum.Insurance,
	// 	name: 'Seguros de viaje',
	// 	icon: <SecurityIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.Circuit,
	// 	name: 'Circuito',
	// 	icon: <SignpostIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.CarRental,
	// 	name: 'Alquiler de auto',
	// 	icon: <CarRentalIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.Bus,
	// 	name: 'Bus',
	// 	icon: <DirectionsBusIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.Train,
	// 	name: 'Tren',
	// 	icon: <TrainIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.Activity,
	// 	name: 'Actividades',
	// 	icon: <LocalActivityIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.FlightAddOn,
	// 	name: 'Adicionales de vuelo',
	// 	icon: <LuggageIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.ThirdPartyPackage,
	// 	name: 'Paquetes de terceros',
	// 	icon: <GroupsIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.Ferry,
	// 	name: 'Ferry',
	// 	icon: <HouseboatIcon />,
	// },
	// {
	// 	id: TypeServiceEnum.Cruise,
	// 	name: 'Crucero',
	// 	icon: <DirectionsBoatIcon />,
	// },
	{
		id: TypeServiceEnum.Other,
		name: 'Otros',
		icon: <MoreHorizIcon />,
	},
	{
		id: TypeServiceEnum.Fee,
		name: 'Fee',
		icon: <AttachMoney />,
	},
];

export interface ITransferService extends IService {
	keyId: string;
	reference: string;
	tariffProvider?: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	transferSegments?: ITransferSegment[];
	currencyRate?: number;
	totalSaleAmountInLocalCurrency?: number;
}

export interface ITransferSegment {
	transferType: TransferTypeEnum[];
	originName: string;
	destinationName: string;
	startDate: string;
	endDate: string;
}

export enum TransferTypeEnum {
	In = 'In',
	InOut = 'InOut',
	InPrivate = 'InPrivate',
	InOutPrivate = 'InOutPrivate',
}

export interface ITypeTransfer {
	id: TransferTypeEnum;
	name: string;
}

export const typeTransfer: ITypeTransfer[] = [
	{
		id: TransferTypeEnum.In,
		name: 'Ida',
	},
	{
		id: TransferTypeEnum.InOut,
		name: 'Ida y vuelta',
	},
	{
		id: TransferTypeEnum.InPrivate,
		name: 'Ida privado',
	},
	{
		id: TransferTypeEnum.InOutPrivate,
		name: 'Ida y vuelta privado',
	},
];

export interface IInsurance extends IService {
	keyId: string;
	reference: string;
	serviceType: TypeServiceEnum;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	serviceName: string;
	residenceCountry: ICodeName;
	startDate: string;
	endDate: string;
	limitDate: string;
}
export interface ICircuitService extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	serviceName: string;
	origin: ICodeName;
	endDestination: ICodeName;
	startDate: string;
	endDate: string;
	limitDate: string;
}
export interface ICarRental extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	destination: ICodeName;
	vehicleType: string;
	pickupLocation: string;
	pickupAt: string;
	dropOffLocation: string;
	dropOffAt: string;
	limitDate: string;
}
export interface IBusService extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	travelType: TravelTypeEnum;
	seatsDistribution?: ISeatsDistribution[];
	origin: ICodeName;
	destination: ICodeName;
	startDate: string;
	endDate: string;
	limitDate: string;
}
export interface ISeatsDistribution {
	passengerId: string;
	seatNumber: string;
	passengerName?: string;
}
export interface ITrainService extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	seatsDistribution: ISeatsDistribution[];
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	travelType: TravelTypeEnum;
	serviceClass: string;
	origin: ICodeName;
	destination: ICodeName;
	startDate: string;
	endDate: string;
	limitDate: string;
}

export interface IActivityService extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	currency: string;
	totalCost: string;
	totalSaleAmount: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	activityServiceType: string;
	serviceName: string;
	useDate: string;
	limitDate: string;
	destination: ICodeName;
}

export interface IFlightAddOm extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes: string;
	internalNotes: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	flightAddOnType: FlightAddOnTypeEnum[];
	serviceDetail: string;
}

export interface IThirdPartyPackage extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	packageName: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes: string;
	internalNotes: string;
	passengerIds: string[];
	destinations: ICodeName[];
	startDate: string;
	endDate: string;
	limitDate: string;
}

export interface IFerry extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	travelType: TravelTypeEnum;
	serviceClass: string;
	origin: ICodeName;
	destination: ICodeName;
	startDate: string;
	endDate: string;
	limitDate: string;
}

export enum ActivityServiceTypeEnum {
	Wanderings = 'Wanderings',
	TicketsOnly = 'TicketsOnly',
	TicketsAndTransfers = 'TicketsAndTransfers',
}

export interface IActivityType {
	id: ActivityServiceTypeEnum;
	name: string;
}

export const activityType: IActivityType[] = [
	{
		id: ActivityServiceTypeEnum.Wanderings,
		name: 'Paseo',
	},
	{
		id: ActivityServiceTypeEnum.TicketsOnly,
		name: 'Solo entradas',
	},
	{
		id: ActivityServiceTypeEnum.TicketsAndTransfers,
		name: 'Entradas y traslados',
	},
];

export enum TravelTypeEnum {
	OW = 'OW',
	RT = 'RT',
}

export interface ITravelType {
	id: TravelTypeEnum;
	name: string;
}

export const tripType: ITravelType[] = [
	{
		id: TravelTypeEnum.OW,
		name: 'Ida',
	},
	{
		id: TravelTypeEnum.RT,
		name: 'Ida y vuelta',
	},
];

export interface ICruiseService extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost?: string;
	saleAmount?: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	cruiseName: string;
	portShipment: string;
	portLanding: string;
	samePort: boolean;
	startDate: string;
	endDate: string;
	limitDate: string;
	rooms?: ICruiseServiceRoom[];
}

export interface ICruiseServiceRoom {
	keyId: string;
	passengerIds?: string[];
	distribution: CruiseRoomDistributionEnum;
	category: CruiseRoomCategoryEnum;
	board: CruiseRoomBoardEnum;
	cost?: string;
	markupPercentage?: string;
	saleAmount?: string;
	totalCost?: string;
	totalSaleAmount?: string;
}

export enum CruiseRoomDistributionEnum {
	Simple = 'Simple',
	Double = 'Double',
	Triple = 'Triple',
	Quadruple = 'Quadruple',
}

export interface ICruiseRoomDistribution {
	id: CruiseRoomDistributionEnum;
	name: string;
}

export const cruiseRoomDistribution: ICruiseRoomDistribution[] = [
	{
		id: CruiseRoomDistributionEnum.Simple,
		name: 'Simple',
	},
	{
		id: CruiseRoomDistributionEnum.Double,
		name: 'Doble',
	},
	{
		id: CruiseRoomDistributionEnum.Triple,
		name: 'Triple',
	},
	{
		id: CruiseRoomDistributionEnum.Quadruple,
		name: 'Cuadruple',
	},
];

export enum CruiseRoomCategoryEnum {
	Internal = 'Internal',
	Suite = 'Suite',
	WithBalcony = 'WithBalcony',
	WithView = 'WithView',
}

export interface ICruiseRoomCategory {
	id: CruiseRoomCategoryEnum;
	name: string;
}

export const cruiseRoomCategory: ICruiseRoomCategory[] = [
	{
		id: CruiseRoomCategoryEnum.Internal,
		name: 'Interno',
	},
	{
		id: CruiseRoomCategoryEnum.Suite,
		name: 'Suite',
	},
	{
		id: CruiseRoomCategoryEnum.WithBalcony,
		name: 'con balcon',
	},
	{
		id: CruiseRoomCategoryEnum.WithView,
		name: 'con vista',
	},
];

export enum CruiseRoomBoardEnum {
	AllInclusive = 'AllInclusive',
	FullBoard = 'FullBoard',
	HalfBoard = 'HalfBoard',
	OnlyRoom = 'OnlyRoom',
}

export interface ICruiseRoomBoard {
	id: CruiseRoomBoardEnum;
	name: string;
}

export const cruiseRoomBoard: ICruiseRoomBoard[] = [
	{
		id: CruiseRoomBoardEnum.AllInclusive,
		name: 'All inclusive',
	},
	{
		id: CruiseRoomBoardEnum.FullBoard,
		name: 'Pension completa',
	},
	{
		id: CruiseRoomBoardEnum.HalfBoard,
		name: 'Media pension',
	},
	{
		id: CruiseRoomBoardEnum.OnlyRoom,
		name: 'Solo alojamiento',
	},
];

export interface IOtherService extends IService {
	keyId: string;
	reference: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	voucherNotes?: string;
	internalNotes?: string;
	passengerIds: string[];
	serviceProvider: IProvider;
	sameTariffServiceProvider: boolean;
	otherServiceType: string;
	serviceName: string;
	startDate: string;
	endDate: string;
	limitDate: string;
	currencyRate?: number;
	totalSaleAmountInLocalCurrency?: number;
}

export interface IFeeService extends IService {
	keyId: string;
	tariffProvider: IProvider;
	unit: ServiceUnitEnum[];
	cost: string;
	saleAmount: string;
	totalCost: string;
	totalSaleAmount: string;
	currency: string;
	markupPercentage: string;
	serviceType: TypeServiceEnum;
	passengerIds: string[];
	feeServiceType: string;
	currencyRate?: number;
	totalSaleAmountInLocalCurrency?: number;
}

export interface ICostVariationLogs {
	serviceCostVariationLogs: IServiceCostVariationLogs[];
	saleOrderCostVariationLogs: ISaleOrderCostVariationLogs[];
}

export interface IServiceCostVariationLogs {
	id: string;
	serviceId: string;
	serviceType: TypeServiceEnum;
	saleAmount: number;
	saleAmountVariation: number;
	totalCost: number;
	totalCostVariation: number;
	grossProfit: number;
	grossProfitVariation: number;
	createdBy: string;
	created: string;
}

export interface ISaleOrderCostVariationLogs {
	id: string;
	saleOrderId: string;
	saleAmount: number;
	saleAmountVariation: number;
	totalCost: number;
	totalCostVariation: number;
	grossProfit: number;
	grossProfitVariation: number;
	dateCreated: string;
}

export interface ICustomersAutocomplete {
	customerId: string;
	customerName: string;
	email: string;
	phone?: string;
	taxIdentificationNumber?: string;
	countryCode: string;
	countryName: string;
}

export interface IChannel {
	id: number;
	name: string;
}

export interface State {
	id: number;
	name: string;
	countryCode: string;
}

export interface Province {
	id: number;
	name: string;
	stateId: number;
	countryCode: string;
}

export interface Municipality {
	id: number;
	name: string;
	stateId: number;
	provinceId: number;
	countryCode: string;
}

export interface NetsuitePlaces {
	states: State[];
	countries: {
		id: number;
		name: string;
	}[];
	provinces: Province[];
	municipalities: Municipality[];
}

export interface TaxDataItem {
	id: number;
	name: string;
	countryCode: 'CO' | 'BO' | 'UY' | 'PY';
	isNetsuiteDocument?: boolean;
	needVerificationNumber?: boolean;
	isAllowToJuridicPerson?: boolean;
	isAllowToPhysicalPersonInvoiceable?: boolean;
}

export interface NetsuiteTaxDataResponse {
	fiscalResponsibilities: TaxDataItem[];
	taxPayerTypes: TaxDataItem[];
	taxRegimes: TaxDataItem[];
	taxResidenceTypes: TaxDataItem[];
	documentTypes: TaxDataItem[];
}
