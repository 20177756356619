// NetsuiteEntitySyncStatus.tsx
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Link, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { INetsuiteSyncStatusDTO } from '../../../../../features/common/types';
import NetsuiteSyncStatus from '../../../../../features/common/components/NetsuiteSyncStatus';

const useStyles = makeStyles(() => ({
	link: {
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		color: 'inherit',
	},
}));

interface NetsuiteEntitySyncStatusProps {
	syncStatus: INetsuiteSyncStatusDTO;
}

const NetsuiteEntitySyncStatus: React.FC<NetsuiteEntitySyncStatusProps> = (props: NetsuiteEntitySyncStatusProps) => {
	const classes = useStyles();

	const { netSuiteId, detailUrlInNetsuite } = props.syncStatus;

	return (
		<Box alignItems="center" display="flex" gap={1} justifyContent={'space-between'}>
			<Grid item xs={2}>
				<NetsuiteSyncStatus syncStatus={props.syncStatus} />
			</Grid>
			<Grid item xs={10} container justifyContent="flex-start" alignItems="center">
				{netSuiteId && detailUrlInNetsuite ? (
					<Link href={detailUrlInNetsuite} target="_blank" rel="noopener" className={classes.link}>
						<Typography variant="body2" component="span" marginRight={1}>
							Ver detalle en Netsuite
						</Typography>
						<OpenInNewIcon fontSize="small" />
					</Link>
				) : (
					<Typography variant="body2" color="textSecondary">
						No hay detalles disponibles
					</Typography>
				)}
			</Grid>
		</Box>
	);
};

export default NetsuiteEntitySyncStatus;
