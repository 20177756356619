import { ApiSaleOrderService, ApiService } from '../../services';
import { AxiosResponse } from 'axios';
import {
	ISaleOrderCreateForm,
	ISalesOrderList,
	ISaleOrderDetail,
	IPassenger,
	IProviderAutocomplete,
	ICodeName,
	IPassengerAutocomplete,
	ISalesOrderCustomer,
	ISelectOption,
	IFlightServiceLeg,
	ICostVariationLogs,
	SegmentEnum,
	ICustomersAutocomplete,
	IServiceCostVariationLogs,
	IChannel,
	ITransaction,
	NetsuitePlaces,
	NetsuiteTaxDataResponse,
	IPatchSaleOrder,
} from './types';
import { ISort } from 'features/common/types';
import { formatSortingFields } from 'features/common/helpers';
import { ICustomerLeisureDTO } from 'features/customers/types';
import {
	AccommodationServiceProviderTypeEnum,
	AccomodationProviderItem,
} from 'features/common/components/AutocompleteAccomodationProviders';
import { FlightLeg } from 'pages/SalesOrder/SalesOrderScreen/components/ServiceForm/FlightService';

interface FlightLegRequest extends FlightLeg {
	keyId: string;
}

export const createSalesOrder = (
	id: string,
	orderToSend: ISaleOrderCreateForm,
): Promise<AxiosResponse<ISaleOrderCreateForm>> => {
	return ApiSaleOrderService.post(`/saleorders/${id}`, orderToSend);
};

export const getSalesOrderList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
	search?: string,
): Promise<AxiosResponse<ISalesOrderList>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiSaleOrderService.get('/saleorders', {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			...(search && { search: search }),
			envelope: true,
		},
	});
};

export const getPaymentReceivedTransactions = (id: string): Promise<AxiosResponse<ITransaction[]>> => {
	return ApiSaleOrderService.get(`/saleorders/${id}/payment-received`);
};

export const getSalesOrderListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<ISalesOrderList>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiSaleOrderService.get('/saleorders', {
		params: {
			page: 1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getSalesOrderId = (id: string): Promise<AxiosResponse<ISaleOrderDetail>> =>
	ApiSaleOrderService.get(`/saleorders/${id}`);

export const patchPassengerForm = (id: string, passengerData: IPassenger[]): Promise<AxiosResponse> =>
	ApiSaleOrderService.patch(`/saleorders/${id}/passengers/`, passengerData);

export const patchServiceForm = (id: string, data: any[]): Promise<AxiosResponse> =>
	ApiSaleOrderService.patch(`/saleorders/${id}/services`, data);

export const postSendAccomodationServiceVoucher = (
	saleOrderId: string,
	serviceId: string,
	sendByWhatsApp: boolean,
): Promise<AxiosResponse> =>
	ApiSaleOrderService.post(`/saleorders/${saleOrderId}/accommodation-services/${serviceId}/vouchers`, {
		sendByWhatsapp: sendByWhatsApp,
	});

//@ts-ignore
export const deleteFlightLeg = (saleOrderId: string, serviceId: string, legId: string): Promise<AxiosResponse> => {
	return ApiSaleOrderService.delete(`/saleorders/${saleOrderId}/flight-services/${serviceId}/legs/${legId}`);
};
//@ts-ignore
export const putFlightLeg = (
	saleOrderId: string,
	serviceId: string,
	data: FlightLegRequest,
): Promise<AxiosResponse> => {
	return ApiSaleOrderService.put(`/saleorders/${saleOrderId}/flight-services/${serviceId}/legs`, data);
};

export const patchSaleOrder = (saleOrderId: string, data: IPatchSaleOrder): Promise<AxiosResponse> =>
	ApiSaleOrderService.patch(`/saleorders/${saleOrderId}`, data);

export const addToQueueToSyncWithNetsuite = (saleOrderId: string): Promise<AxiosResponse> =>
	ApiSaleOrderService.put(`/saleorders/sync/${saleOrderId}`, {});

export const matchSaleRepresentatives = (): Promise<AxiosResponse> =>
	ApiSaleOrderService.put('/sale-representative', {});

export const patchEnableService = ({
	saleOrderId,
	serviceId,
	enable,
}: {
	saleOrderId: string;
	serviceId: string;
	enable: boolean;
}): Promise<AxiosResponse> =>
	ApiSaleOrderService.patch(`/saleorders/${saleOrderId}/services/${serviceId}/enable`, {
		enable,
	});

export const putServiceForm = ({
	saleOrderId,
	serviceId,
	data,
}: {
	saleOrderId: string;
	serviceId: string;
	data: any;
}): Promise<AxiosResponse> => ApiSaleOrderService.put(`/saleorders/${saleOrderId}/services/${serviceId}`, data);

export const getProviderAutocomplete = (value: string): Promise<AxiosResponse<IProviderAutocomplete[]>> => {
	return ApiSaleOrderService.get('/providers/autocomplete', {
		params: {
			value,
		},
	});
};

export const getAccomodationProviderAutocomplete = (
	search: string,
): Promise<AxiosResponse<AccomodationProviderItem[]>> => {
	return ApiSaleOrderService.get('/accommodation-service-providers/autocomplete', {
		params: {
			search,
			limit: 100,
		},
	});
};
///accommodation-service-providers/{accommodationServiceProviderId}
export const getAccomodationProviderId = (
	providerId: string,
	accommodationServiceProviderType: AccommodationServiceProviderTypeEnum,
): Promise<AxiosResponse<IProviderAutocomplete>> =>
	ApiSaleOrderService.get(`/accommodation-service-providers/${providerId}/${accommodationServiceProviderType}`);

export const getProviderId = (providerId: string): Promise<AxiosResponse<IProviderAutocomplete>> =>
	ApiSaleOrderService.get(`/providers/${providerId}`);

export const getCurrencies = (): Promise<AxiosResponse<ICodeName[]>> => ApiSaleOrderService.get('/currencies');

export const getOIDIssues = (): Promise<AxiosResponse<ISelectOption[]>> => ApiSaleOrderService.get('/oidissues');

export const getAutocompletePassenger = (value: string): Promise<AxiosResponse<IPassengerAutocomplete[]>> => {
	return ApiSaleOrderService.get('/passengers/autocomplete', {
		params: {
			value,
		},
	});
};

export const getPassengerId = (id: string): Promise<AxiosResponse<IPassenger>> =>
	ApiSaleOrderService.get(`/passengers/${id}`);

export const getSalesOrderCustomerList = (
	page = 0,
	size = 10,
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<ISalesOrderCustomer>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiSaleOrderService.get('/customers/leisures', {
		params: {
			page,
			size,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const getSalesOrderCustomerListToExport = (
	sortingFields: ISort[],
	filters?: Record<string, string | number | boolean>,
): Promise<AxiosResponse<ISalesOrderCustomer>> => {
	const sorts = formatSortingFields(sortingFields);
	return ApiSaleOrderService.get('/customers/leisures', {
		params: {
			page: 1,
			...(sorts.length > 0 && { sort: sorts }),
			...(filters && filters),
			envelope: true,
		},
	});
};

export const parseGDSAirRawDataSaleOrder = async (gdsRawData: string): Promise<AxiosResponse<IFlightServiceLeg[]>> =>
	ApiService.post('api/quotations/flights/converters/tst', gdsRawData, {
		headers: { 'Content-Type': 'text/plain' },
	});

export const getCostVariationLogs = (id: string): Promise<AxiosResponse<ICostVariationLogs>> =>
	ApiSaleOrderService.get(`/saleorders/${id}/costvariationlogs`);

export const getCostVariationLogsByService = (
	saleOrderId: string,
	serviceId: string,
): Promise<AxiosResponse<IServiceCostVariationLogs[]>> =>
	ApiSaleOrderService.get(`/saleorders/${saleOrderId}/services/${serviceId}/cost-variation-logs`);

export const getCustomerAutocomplete = (
	value: string,
	customerType: SegmentEnum,
): Promise<AxiosResponse<ICustomersAutocomplete[]>> => {
	return ApiSaleOrderService.get('/customers/autocomplete', {
		params: {
			value,
			customerType,
		},
	});
};

export const getCustomerById = (id: string): Promise<AxiosResponse<ICustomerLeisureDTO>> =>
	ApiSaleOrderService.get(`/customers/${id}`);

export const asociateCustomerToSaleOrder = (id: string, customerId: string): Promise<AxiosResponse> =>
	ApiSaleOrderService.patch(`/saleorders/${id}/customers/${customerId}`);

export const getPax = ({ paxId }: { paxId: string }): Promise<AxiosResponse> =>
	ApiSaleOrderService.get(`/passengers/${paxId}`);

export const removePax = ({ saleOrderId, paxId }: { saleOrderId: string; paxId: string }): Promise<AxiosResponse> =>
	ApiSaleOrderService.delete(`/saleorders/${saleOrderId}/passengers/${paxId}`);

export const updatePax = ({
	saleOrderId,
	paxId,
	payload,
}: {
	saleOrderId: string;
	paxId: string;
	payload: IPassenger;
}): Promise<AxiosResponse> => ApiSaleOrderService.put(`/saleorders/${saleOrderId}/passengers/${paxId}`, payload);

export const netsuiteTaxData = (): Promise<AxiosResponse<NetsuiteTaxDataResponse>> =>
	ApiSaleOrderService.get('netsuite-tax-data');

export const getVariationLogs = (saleOrderId: string): Promise<AxiosResponse> =>
	ApiSaleOrderService.get(`/saleorders/${saleOrderId}/cost-variation-logs`);

export const getChannels = (): Promise<AxiosResponse<IChannel[]>> => {
	return ApiSaleOrderService.get('/channels');
};

export const getNetsuitePlaces = (): Promise<AxiosResponse<NetsuitePlaces>> =>
	ApiSaleOrderService.get('/netsuite-places');
