import { ApiService, ApiMeVueloService, ApiSaleOrderService } from 'services';
import { LoginMeVueloPayload, LoginMeVueloResponse, LoginPayload, LoginResponse, User } from 'features/auth/types';
import { CookieService, SessionService } from './CookieService';
import { actions } from '../slice';
import { Store } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export class AuthService {
	static postLogin = (payload: LoginPayload): Promise<AxiosResponse<LoginResponse>> =>
		ApiService.post<LoginResponse>('api/signin', payload);

	static postLoginMevuelo = (payload: LoginMeVueloPayload): Promise<AxiosResponse<LoginMeVueloResponse>> =>
		ApiMeVueloService.post<LoginMeVueloResponse>('auth/login', payload);

	static postLogout = async (): Promise<void> => {
		await ApiService.post<void>('api/signout');
		CookieService.clearToken();
	};

	static getProfile = (): Promise<AxiosResponse<User>> => ApiService.get<User>('api/users/profiles');
}

export const initAuthInterceptors = (store: Store): void => {
	ApiService.interceptors.request.use((config) => {
		const token = CookieService.getToken();
		if (token) {
			config.headers['X-Auth-Token'] = token;
		}
		return config;
	});

	ApiService.interceptors.response.use(undefined, (error) => {
		if (error.response?.status === 403) {
			CookieService.clearToken();
			SessionService.clearApiKey();
			store.dispatch(actions.logout());
		}
		return Promise.reject(error);
	});

	ApiSaleOrderService.interceptors.request.use((config) => {
		const token = CookieService.getToken();
		if (token) {
			config.headers['X-Auth-Token'] = token;
		}
		return config;
	});

	ApiSaleOrderService.interceptors.response.use(undefined, (error) => {
		if (error.response?.status === 403) {
			CookieService.clearToken();
			SessionService.clearApiKey();
			store.dispatch(actions.logout());
		}
		return Promise.reject(error);
	});
};
