import React from 'react';

import { Grid, Typography } from '@mui/material';
import { Layout } from 'features/common/components/Layout';
import SaleOrdersSyncProcessStatus from './Netsuite/components/SalesOrderSyncProcessStatus';

export const SalesOrderSyncProcessStatusPage = (): JSX.Element => {
	return (
		<Layout>
			<Grid container paddingY={5} justifyContent={'center'}>
				<Typography variant="h4" style={{ fontWeight: 500 }}>
					Estado de Proceso de Sincronización de Sales Order con Netsuite
				</Typography>
				<Grid item xs={12} paddingY={5}>
					<SaleOrdersSyncProcessStatus />
				</Grid>
			</Grid>
		</Layout>
	);
};
