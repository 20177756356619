export default {
	auth: {
		login: '/auth/login',
		pwdChange: '/auth/change-passowrd',
	},
	home: '/',
	clientNotLogin: '/client-not-login',
	dataStudio: '/report',
	quotaData: '/quotaData',
	refund: '/devoluciones',
	activityLogList: '/activity-logs',
	quotations: {
		saleReport: '/saleReport',
		previewClient: '/proposal/:id',
		edit: '/quotations/:id',
		editOnlyFlights: '/quotations/:id/only-flights',
		new: '/quotations/new',
		newOnlyFlights: '/quotations/new-only-flights',
		list: '/quotations',
		description: '/quotation/description',
		defaultNotIncludes: '/quotation/defaultnotincludes',
	},
	flightIssueRule: {
		list: '/flight-issue-rules',
		upsert: '/flight-issue-rules/:id',
	},
	salesOrder: {
		new: '/salesorder/new',
		list: '/salesorder/list',
		edit: '/salesorder/:id',
		screen: '/salesorder-screen/:id',
		customersSyncStatus: '/salesorder-netsuite-customers-sync-status',
		salesOrderSyncStatus: '/salesorder-netsuite-sync-status',
	},
	hotels: {
		search: '/hotels',
		fareCard: '/hotels/farecard',
		searchReservations: '/reservations',
	},
	agency: {
		list: '/agencies',
		new: '/agency/new',
		update: '/agency/:id/update',
	},
	user: {
		list: '/users',
		new: '/user/new',
	},
	stats: {
		package: '/stats/packages',
		automaticRoute: '/stats/routes',
		ratesList: '/stats/rates',
	},
	payment: {
		order: '/payments/order/:id',
		orderNaranja: '/payments/viaje-naranja-order',
		orderCash: '/payments/order-cash',
		wireTransferCheck: '/payments/wiretransfers/check',
		clientTransaction: '/payments/client/transaction/:orderId',
		bancardCheckConfirmation: '/payments/client/transaction/bancard/check-payment/:shopProcessId',
		atcCheckConfirmation: '/payments/client/transaction/atc/check-payment',
		thankYouTransaction: '/payments/client/thank-you',
		wompiCheckConfirmation: '/payments/client/transaction/wompi/check-payment/:reference',
		cancelTransaction: '/payments/client/cancel-payment',
	},
	transactions: {
		viajeNaranjaItauPointTransactionList: '/transactions/itaupoints',
		viajeNaranjaBancardTransactionList: '/viaje-naranja-bancard-transaction-list',
		cashTransactions: '/cash-transactions',
		list: '/transactions',
		wireTransferTransactions: '/wiretransfertransactions',
		gatewayTransactionList: '/transactionsingateway/:orderTypeId/list',
		details: '/transaction/:id',
	},
	crosses: {
		list: '/transactions/crosses',
	},
	authorizations: {
		list: '/transactions/authorizations',
		details: '/authorization/:id',
	},
	packageExplorer: {
		list: '/packageexplorer',
		destinationDetail: '/packageexplorer/destination',
	},
	flightExplorer: {
		list: '/flightexplorer',
		destinationDetail: '/flightexplorer/destination',
	},
	providers: {
		list: '/providers',
	},
	customers: {
		list: '/customers',
	},
};
