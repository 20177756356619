import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

interface DatosDeLaVentaProps {
	saleAmount: number;
	totalCost: number;
	grossProfitPercentage: number;
	grossProfit: number;
	chargedAmount: number;
	pendingPayAmount: number;
	travelDepartureDate: string;
	currency: string;
}

const ColoredLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 20, // Makes the progress bar thicker
	'& .MuiLinearProgress-bar': {
		transition: theme.transitions.create('width', {
			duration: theme.transitions.duration.standard,
		}),
	},
	'& .MuiLinearProgress-bar1Determinate': {
		backgroundColor: theme.palette.success.main,
	},
	'& .MuiLinearProgress-colorPrimary': {
		backgroundColor: theme.palette.error.main,
	},
}));

const balanceStyleAndText = (amount: number | undefined, departureDate: string | undefined) => {
	if (amount === undefined || departureDate === undefined) {
		return { style: { color: 'inherit' }, text: '' };
	}

	const today = new Date();
	const departureDateObj = new Date(departureDate);
	const diffTime = departureDateObj.getTime() - today.getTime();
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	if (amount > 0 && diffDays < 0) {
		return {
			style: { color: 'red', fontWeight: 'bold' as const },
			text: (
				<>
					{`PAX VIAJÓ HACE ${-diffDays} ${-diffDays === 1 ? 'DÍA' : 'DÍAS'}`}
					<br />
					{'🤬 URGENTE COBRAR SALDO'}
				</>
			),
		};
	} else if (amount > 0 && diffDays < 30) {
		return {
			style: { color: 'red', fontWeight: 'bold' as const },
			text: (
				<>
					{`${diffDays} ${diffDays === 1 ? 'día' : 'días'} para la fecha de viaje`}
					<br />
					{'COBRAR SALDO 😠'}
				</>
			),
		};
	} else if (amount > 0 && diffDays >= 30 && diffDays <= 45) {
		return {
			style: { color: 'red' },
			text: (
				<>
					{`${diffDays} ${diffDays === 1 ? 'día' : 'días'} para la fecha de viaje`}
					<br />
					{'COBRAR SALDO 😟'}
				</>
			),
		};
	}
	return { style: { color: 'inherit' }, text: '' };
};

const DatosDeLaVenta: React.FC<DatosDeLaVentaProps> = ({
	saleAmount,
	totalCost,
	grossProfitPercentage,
	grossProfit,
	chargedAmount,
	pendingPayAmount,
	travelDepartureDate,
	currency,
}) => {
	const total = chargedAmount + pendingPayAmount;
	const chargedPercent = total > 0 ? (chargedAmount / total) * 100 : 0;
	const { style, text } = balanceStyleAndText(pendingPayAmount, travelDepartureDate);

	return (
		<Card elevation={4}>
			<CardContent>
				<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
					<Typography align="left" variant="h6">
						Datos de la venta
					</Typography>
					{grossProfitPercentage > 16 && pendingPayAmount === 0 && <Typography variant="body2">¡Crack! 🤩</Typography>}
				</Box>
				<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
					<Typography variant="body2">Venta:</Typography>
					<Typography variant="body2">
						{currency} {saleAmount}
					</Typography>
				</Box>
				<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
					<Typography variant="body2">Costo:</Typography>
					<Typography variant="body2">
						{currency} {totalCost}
					</Typography>
				</Box>
				<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
					<Typography variant="body2">MB %:</Typography>
					<Typography variant="body2">{grossProfitPercentage}%</Typography>
				</Box>
				<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
					<Typography variant="body2">MB A:</Typography>
					<Typography variant="body2" fontWeight="bold">
						{currency} {grossProfit}
					</Typography>
				</Box>
				<Box mt={2} mb={1} display="flex" flexDirection="column" width="100%">
					<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
						<Typography variant="body2">Monto cobrado:</Typography>
						<Typography variant="body2">
							{currency} {chargedAmount}
						</Typography>
					</Box>
					<Box display="flex" justifyContent="space-between" width="100%" gap={1}>
						<Typography variant="body2">Saldo pendiente:</Typography>
						<Typography variant="body2">
							{currency} {pendingPayAmount}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ position: 'relative', width: '100%', mt: 2 }}>
					<ColoredLinearProgress variant="determinate" value={chargedPercent} />
				</Box>
				<Typography variant="body2" align="center" mt={1} mb={2}>
					Cobrado: {chargedPercent.toFixed(2)}%
				</Typography>
				{text && (
					<Typography variant="body2" align="center" style={style} mt={2}>
						{text}
					</Typography>
				)}
			</CardContent>
		</Card>
	);
};

DatosDeLaVenta.propTypes = {
	saleAmount: PropTypes.number.isRequired,
	totalCost: PropTypes.number.isRequired,
	grossProfitPercentage: PropTypes.number.isRequired,
	grossProfit: PropTypes.number.isRequired,
	chargedAmount: PropTypes.number.isRequired,
	pendingPayAmount: PropTypes.number.isRequired,
	travelDepartureDate: PropTypes.string.isRequired,
	currency: PropTypes.string.isRequired,
};

export default DatosDeLaVenta;
