import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Button, Chip, Fade, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import {
	DateToFromFilterModel,
	FilterModel,
	FilterType,
	SelectFilterModelMultiple,
	SelectFilterModelSimple,
} from '../../types';
import AddIcon from '@mui/icons-material/Add';
import { FilterModal } from './FilterModal';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateToShowUser, getFilterModalTitle } from 'features/common/helpers';

interface FilterGridProps {
	filters: FilterModel[];
	handleAppliedFilters: (filters: FilterModel[]) => void;
	preAppliedFilters?: FilterModel[];
}

const useStyles = makeStyles(() => ({
	deleteFilters: {
		marginLeft: '2px',
		borderRadius: '5px!important',
		backgroundColor: '#c73848 !important',
		color: 'white !important',
	},
}));
export const FilterGrid = ({ filters, handleAppliedFilters, preAppliedFilters }: FilterGridProps): JSX.Element => {
	const user = useSelector((state: RootState) => state.auth.user);
	const currentUserName = user ? user.name : '';
	const classes = useStyles();
	const [clickPosition, setClickPosition] = React.useState<{ x: number; y: number }>({
		x: 0,
		y: 0,
	});
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>(preAppliedFilters ? [...preAppliedFilters] : []);
	const [filterToShowInDialog, setFilterToShowInDialog] = useState<FilterModel | null>(null);

	const open = Boolean(anchorEl);

	useEffect(() => {
		// Esta línea actualizará el estado interno cada vez que preAppliedFilters cambie
		setFilterApplied(preAppliedFilters || []);
	}, [preAppliedFilters]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setClickPosition({ x: event.clientX, y: event.clientY });
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenuFilter = () => {
		setAnchorEl(null);
	};

	const getFilterNotApplied = (): FilterModel[] =>
		filters.filter((x) => !filterApplied.find((applied) => applied.key == x.key));

	const handleShowAddNewFilter = (filter: FilterModel) => {
		setAnchorEl(null);
		setFilterToShowInDialog(filter);
	};

	const handleCloseModal = () => {
		setFilterToShowInDialog(null);
	};
	const handleApplyFilter = (filter: FilterModel) => {
		const newFilters = [...filterApplied];
		const indexInFilters = newFilters.findIndex((x) => x.key == filter.key);
		indexInFilters != -1 ? (newFilters[indexInFilters] = filter) : newFilters.push(filter);
		setFilterApplied(newFilters);
		handleAppliedFilters(newFilters);
		setFilterToShowInDialog(null);
	};

	const handleDeleteFilter = (filter: FilterModel) => {
		const newFilters = [...filterApplied];
		const indexInFilters = newFilters.findIndex((x) => x.key == filter.key);
		if (indexInFilters != -1) {
			newFilters.splice(indexInFilters, 1);
			setFilterApplied(newFilters);
			handleAppliedFilters(newFilters);
		}
	};
	const handleClickInChip = (event: React.MouseEvent<HTMLElement>, filter: FilterModel) => {
		setClickPosition({ x: event.clientX, y: event.clientY });
		setFilterToShowInDialog(filter);
	};
	const getChipLabel = (filter: FilterModel): string => {
		let begin = '';
		switch (filter.type) {
			case FilterType.STRING:
			case FilterType.NUMERIC:
				begin = `${getFilterModalTitle(filter)} ` + filter.value;
				break;
			case FilterType.LIST:
				if (filter instanceof SelectFilterModelMultiple) {
					const valueToRender = Array.isArray(filter.value)
						? [...filter.value].map((id) => filter.data.find((x) => x.id == id)?.name)
						: [];
					begin = `${filter.label} igual a ${valueToRender.join(',')}`;
				} else if (filter instanceof SelectFilterModelSimple) {
					begin = `${filter.label} igual a ` + filter.data.find((x) => x.id == filter.value)?.name;
				}
				break;
			case FilterType.BOOLEAN:
				begin = `${filter.label} igual a ` + (filter.value ? 'Si' : 'No');
				break;
			case FilterType.DATE:
				if (typeof filter.value === 'string') {
					begin = `${filter.label} igual a ${formatDateToShowUser(filter.value)}`;
				}
				break;
			case FilterType.DATEFROMTO:
				if (filter instanceof DateToFromFilterModel && filter.value && filter.secondValue) {
					begin =
						`${filter.label} desde ` +
						formatDateToShowUser(filter.value) +
						' hasta ' +
						formatDateToShowUser(filter.secondValue);
				}
				break;
			default:
				begin = `${filter.label} igual a ${filter.value}`;
				break;
		}
		return begin;
	};

	const handleDeleteAllFilter = () => {
		setFilterApplied([]);
		handleAppliedFilters([]);
	};

	return (
		<Stack direction="row" spacing={1}>
			<div>
				{filterApplied.map((x) => (
					<Chip
						key={`chip-${x.key}`}
						label={getChipLabel(x)}
						onClick={(event) => handleClickInChip(event, x)}
						onDelete={() => handleDeleteFilter(x)}
					/>
				))}

				{filterApplied.length != filters.length && (
					<Button
						variant="contained"
						size="small"
						startIcon={<AddIcon />}
						sx={{ height: 40 }}
						id="fade-button"
						aria-controls={open ? 'fade-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						Añadir filtros
					</Button>
				)}
				<Tooltip title="Clic para filtrar unicamente las ordenes creadas por vos">
					<Button
						variant="contained"
						size="small"
						onClick={() =>
							handleApplyFilter({
								key: 'createdBy',
								label: 'Vendedor contiene',
								value: currentUserName,
								type: FilterType.STRING,
							})
						}
						sx={{ height: 40, marginLeft: 1 }}
					>
						SOLO LAS MIAS
					</Button>
				</Tooltip>
				{filterApplied.length > 0 && (
					<Tooltip title="Borrar filtros">
						<IconButton onClick={handleDeleteAllFilter} className={classes.deleteFilters}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleCloseMenuFilter}
					sx={{ maxHeight: '300px' }}
					TransitionComponent={Fade}
				>
					{getFilterNotApplied().map((x) => (
						<MenuItem key={x.key} onClick={() => handleShowAddNewFilter(x)}>
							{getFilterModalTitle(x)}
						</MenuItem>
					))}
				</Menu>
				{filterToShowInDialog && (
					<FilterModal
						filter={filterToShowInDialog}
						open={filterToShowInDialog != null}
						addFilterXPosition={`${clickPosition.x}px`}
						addFilterYPosition={`${clickPosition.y}px`}
						handleClose={handleCloseModal}
						handleApplyFilter={handleApplyFilter}
					/>
				)}
			</div>
		</Stack>
	);
};
