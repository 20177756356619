import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

interface Props {
	onImageUpload: (base64Image: string) => void;
	initialPreview?: string | null;
}

const ImageUploader = ({ onImageUpload, initialPreview }: Props): JSX.Element => {
	const [preview, setPreview] = useState<string | null>(null);

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64Image = reader.result as string;
				setPreview(base64Image);
				onImageUpload(base64Image); // Aquí enviamos la imagen en base64
			};
			reader.readAsDataURL(file);
		}
	};

	useEffect(() => {
		initialPreview && setPreview(initialPreview);
	}, [initialPreview]);

	return (
		<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
			<Button variant="contained" component="label">
				Cargar Imagen
				<input type="file" accept="image/*" hidden onChange={handleImageChange} />
			</Button>
			{preview && (
				<Box mt={2} display="flex" flexDirection="column" alignItems="center">
					<Typography variant="subtitle1">Vista previa:</Typography>
					<img src={preview} alt="Preview" style={{ maxWidth: '100%', height: 'auto', marginTop: 8 }} />
				</Box>
			)}
		</Box>
	);
};

export default ImageUploader;
